import { useLotteryFiltersStore } from ".."
import { useValue } from "../../../../components/base/hooks/useValue"
import computeCombinations from "./storeActions/computeCombinations"
import setNumber from "./storeActions/setNumber"

export const useNumberCombinationStore = () => {
    const store = useLotteryFiltersStore()
    return store.state.NumberCombinationStore
}

export const MakeNumberCombinationStore = () => {
    const state = {
        currentNumber : 0,
        Numbers: useValue([0,0,0,0,0,0,0,0]),
        IsComputing: useValue(false)
    }

    return {
        state,
        setNumber: setNumber.bind(state),
        computeCombinations: computeCombinations.bind(state) 
    }
}