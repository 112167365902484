import api from "../../../utils/api"

export const loadWinningNumbers = state => async () => {
    try
    {
        let {data} = await api.get("/lottery/winning-numbers")
        state.powerballWinningNumbers = data.powerballWinningNumbers || {}
        state.megaMillionsWinningNumbers = data.megaMillionsWinningNumbers || {}
    }
    catch(err)
    {
        console.log(err)
    }
}