import { useValue } from "../../../../components/base/hooks/useValue"

export const MakeHeaderStore = () => {
    const state = {
        IsMenuOpen: useValue(false)
    }

    return {
        state
    }
}