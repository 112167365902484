import styled from 'styled-components'

export const BgDiv = styled.div`
    /*flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8*/
    
    background-color: ${props => props.theme.colors.black(0,0)};
    display: flex;
    min-height: 100vh;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    padding: 48px 24px;

    @media (min-width: 1024px) {
        padding: 48px 32px;
    }

`