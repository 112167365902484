import { createContext, useEffect, useState } from "react"
import { Loader } from "../../elements/Loader"

const EmptyContext = createContext(null)

export const StoreLoader = ({store, Component, context=EmptyContext}) => {

    const [loaded, setLoaded] = useState(typeof store.load === "function" ? false : true)
    const [error, setError] = useState(null)

    useEffect(() => {
        if(!loaded)
        {
            store.load().then(() => {
                setLoaded(true)
            }).catch(err => {
                setError(err.message)
            })
        }
    }, [])

    if(loaded)
    {
        return (
            <context.Provider value={store}>
                <Component />
            </context.Provider>
        )
    }

    if(error)
    {
        return <div className="text-xl emi-bold">{error}</div>
    }

    return <div className="p-4"><Loader /></div>

}