import { useNavigate } from "react-router-dom"
import authReducer from "../../App/reducers/auth"
import { Loader } from "../../components/base/elements/Loader"
import { useValue } from "../../components/base/hooks/useValue"
import api, { setAuthToken } from "../../utils/api"
import { BgDiv } from "./Style"
import { useRef } from "react"


export const Login = () => {

    const IsSigningIn = useValue(false)
    const ErrorMessage = useValue(null)

    const emailRef = useRef()
    const passwordRef = useRef()

    const navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        
        IsSigningIn.set(true)
        try
        {
          const {data} = await api.post('/auth/login', {
            email: emailRef.current.value,
            password: passwordRef.current.value
          })

          setAuthToken(data.token)
          authReducer.loadUser(data)

          if(data.accountType === "SUPER_ADMIN")
          {
            navigate('/dashboard')
          }
          else
          {
            navigate('/')
          }
        }
        catch(err)
        {
          console.log(err)
          ErrorMessage.set(err.message)
        }
        IsSigningIn.set(false)
    }

    return (
        <>
          <BgDiv>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <ErrorMessage render={error => <>
                {error !== null &&
                  <>
                    <img
                      className="w-auto rounded-md"
                      src="/images/errorLock.jpg"
                      alt="Your Company"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-error">
                      {error}
                    </h2>
                  </>
                }
                {error === null &&
                  <>
                    <img
                      className="w-auto rounded-md"
                      src="/images/loginKey.jpg"
                      alt="Your Company"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-primary">
                      Sign in to your account
                    </h2>
                  </>
                }
              </>} />
              
            </div>
    
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" action="#" method="POST" onSubmit={onSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      ref={emailRef}
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-primary/10 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
    
                <div>
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                      Password
                    </label>
                    {/*<div className="text-sm">
                      <a href="#" className="font-semibold text-indigo-400 hover:text-indigo-300">
                        Forgot password?
                      </a>
                    </div>*/}
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      ref={passwordRef}
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-primary/10 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
    
                <div>
                  <IsSigningIn render={isSigningIn => <>
                    {!isSigningIn && 
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-primary/90 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        Sign in
                      </button>
                    }
                    {isSigningIn && 
                      <Loader size="28px"/>
                    }
                  </>} />
                  
                </div>
              </form>
            </div>
          </BgDiv>
        </>
      )
}