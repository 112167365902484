import styled from 'styled-components'

/* Props:
    active (boolean): Whether the card is clickable or not
*/
export const FilterCard = styled.div`
    width: ${props => props.selected ? "320px" : "155px"};
    display: inline-block;
    height: ${props => props.selected ? "128px" : "64px"};
    background-color: ${props => props.active ? ( props.selected ? props.theme.colors.primary() : props.theme.colors.white()) : props.theme.colors.bg(-50,10)};
    box-shadow: ${props => props.active ? 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' : 'none'};
    cursor: ${props => props.active ? (props.selected ? 'default' : 'pointer') : 'not-allowed'};
    border-radius: 7px;
    justify-self: center;
    transition: all 0.6 ease;
    ${props => (props.pulsate && !props.selected) ? 'animation: bg-pulse 1.5s infinite ease;' : ''}

    @keyframes bg-pulse {
        0% {
            background-color: ${props => props.theme.colors.white()};
        }
        50% {
            background-color: ${props => props.theme.colors.primary(-10,20)};
            transform: scale(1.05);
        }
        100% {
            background-color: ${props => props.theme.colors.white()};
        }
    }

    p {
        font-size: 16px; 
        color: ${props => props.selected ? props.theme.colors.white() : "#888"};
    }

    div {
        font-size: 22px;
        color: ${props => props.theme.colors.secondary()};
    }
`

export const LotteryImage = styled.img`
    width: 144px;
    height: 73px;
    border-radius: 7px;
`

/* Props:
    error (boolean): Whether the button should disabled or not
*/
export const ApplyFilterButton = styled.button`
    /*w-full bg-indigo-500 text-white text-sm font-bold py-2 px-4 rounded-md hover:bg-indigo-600 transition duration-300*/
    background-color: ${props => props.error ? props.theme.colors.secondary(-30,10) : props.theme.colors.secondary()};
    color: ${props => props.error ? props.theme.colors.white(-100,-30) : props.theme.colors.white()};
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;

    ${props => !props.error ? `
        &:hover {
            background-color: ${props.theme.colors.secondary(10,10)};
        }
    `
    : ''}

    &:hover > div {
        opacity: 1;
    }
`

/* Props:
    top (size): The distance from the top of the tooltip
    indicatorLeft (size): The distance from the left of the tooltip indicator
*/
export const Tooltip = styled.div`
    width: 100%;
    min-width: 200px;
    background-color: ${props => props.theme.colors.error()};
    color: ${props => props.theme.colors.white()} !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    /*font-family: Arial, Helvetica, sans-serif;*/
    transition: all .250s cubic-bezier(0, 0, 0.2, 1);
    position: absolute;
    left: 0px;
    top: ${props => props.top || "0px"};
    border-radius: 5px;
    z-index: 5;
    padding: 4px 4px;
    opacity: 0;
    pointer-events: none;

    &:after {
        content: "";
        position: absolute;
        top: -7px;
        left: ${props => props.indicatorLeft || "215px"};
        transform: translate3d(-50%, 0, 0);
        border-width: 0 0.5em 0.5em 0.5em;
        border-color: transparent transparent ${props => props.theme.colors.error()} transparent;
    }
`

export const FilterBodySmallButton = styled.button`
    background-color: ${props => props.theme.colors.secondary()};
    color: ${props => props.theme.colors.white()};
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;

    &:hover {
        background-color: ${props => props.theme.colors.secondary(10,10)};
    }
`