export default function setPrintInterval(newValue)
{
    newValue = parseInt(newValue)
    let newFilters = {...this.FileDownloadFilters.get()}
    newFilters.printEvery = newValue
    newFilters.numberOfTickets = Math.floor(this.NumberCombinationStore.state.currentNumber / newValue)
    if(typeof newFilters.numberOfCards !== "undefined")
    {
        newFilters.numberOfCards = Math.floor(newFilters.numberOfTickets / 5)
    }
    this.FileDownloadFilters.set(newFilters)
}