import { useEffect, useRef } from 'react'
import { FilterButtons } from "./FilterButtons"
import { NumberDisplay } from "./NumberDisplay"
import { AffiliateWarning, AnimatedArrowContainer, Container1280, FilterSectionBody, HeroContainer, HeroWrapper, LotteryIconCombo, PageMargin, TitleHolder, Title_Better, Title_LotterOdds } from "./Style"
import { LotteryFiltersStoreContext, MakeLotteryFilterStore } from "./store"
import { PaymentSection } from './PaymentSection'
import { FileDownloadSection } from './FileDownloadSection'
import { Footer } from '../../App/theme/Footer'
import { ContactUsSection } from './ContactUsSection'

export const Home = () => {

    const store = MakeLotteryFilterStore()
    const filtersRef = useRef()

    const {
        state: {
            MaxActiveFilter,
            DemoNumberList
        },
        loadPriceSettings
    } = store

    useEffect(() => {
        if (filtersRef.current) {
            //setTimeout(() => filtersRef.current.scrollIntoView({behavior: "smooth", block: 'end', inline: 'nearest'}), 500)
            setTimeout(() => window.scrollTo({ top: window.innerHeight * 0.28, behavior: "smooth" }), 500)
            //TO redo
        }
    }, [filtersRef])

    useEffect(() => {
        loadPriceSettings()
    }, [])

    return (
        <LotteryFiltersStoreContext.Provider value={store}>
            <HeroWrapper>
                <HeroContainer />
                <LotteryIconCombo src="/images/lotteryIconCombo.png" />
            </HeroWrapper>

            <PageMargin>
                <TitleHolder>
                    <div><Title_Better>Better</Title_Better></div>
                    <div><Title_LotterOdds>Lottery Odds</Title_LotterOdds></div>
                </TitleHolder>

                {/*<AnimatedArrowContainer>
                    <p className="scroll-down"><a className="animate"></a></p>
                </AnimatedArrowContainer>*/}

                <AffiliateWarning>
                    * betterlotteryodds.com is not affiliated with any lottery commission.
                </AffiliateWarning>

                <FilterSectionBody>
                    <Container1280>
                        <h1 className='text-4xl bold text-center w-[800px] max-w-[95%] m-auto mb-8'>
                            The world's only number generator that eliminates unlikely numbers!
                        </h1>

                        <div className="font-title text-4xl text-primary pl-2">Filters</div>
                        <FilterButtons filtersRef={filtersRef} />

                        <NumberDisplay />

                        <FileDownloadSection />

                        <ContactUsSection />

                    </Container1280>

                    <Footer />


                </FilterSectionBody>


            </PageMargin>
        </LotteryFiltersStoreContext.Provider>
    )
}