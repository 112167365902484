import {useState, useEffect, useRef} from 'react'

export const useValueRef = (val, returnComponentOnly = true) => {


    let subscribers = useRef(new Set())

    let currentValue = useRef(val)

    const get = () => currentValue.current

    const set = (newVal, update=true) => {
        currentValue.current = newVal
        if(update)
        {
            subscribers.current.forEach(setState => setState(currentValue.current))
        }
    }

    const onChange = (func) => {
        subscribers.current.add(func)
        return () => subscribers.current.delete(func)
    }

    const Value = ({mapObject = null, mapArray = null, render = null, conditionalRender=null, children}) => {

        const [state, setState] = useState(get())

        useEffect(() => {
            subscribers.current.add(setState)
            setState(state => get())
            return () => subscribers.current.delete(setState)
        }, [])

        if(render)
        {
            return render(state)
        }

        if(conditionalRender)
        {
            if(state)
            {
                return <>{conditionalRender(state)}</>
            }
            else
            {
                return <></>
            }
        }

        if(mapArray)
        {
            return state.map((value, index) => mapArray(value,index))
        }

        if(mapObject)
        {
            return Object.keys(state).map((key, index) => mapObject(key,state[key],index))
        }

        if(children)
        {
            return <>{children}</>
        }

        return (
            <>{state}</>
        )
    }


    if(returnComponentOnly)
    {
        Value.get = get
        Value.set = set
        Value.onChange = onChange
        return Value
    }
    else
    {
        return {Value, get, set, onChange}
    }
}