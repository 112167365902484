import { useEffect } from "react"
import authReducer, { useAuthReducer } from "../../App/reducers/auth"
import { HomeRedirect } from "../HomeRedirect"
import { VipGuests } from "../VipGuests"
import { MakeDashboardStore } from "./store"
import { PriceControl } from "../PriceControl"
import { Analytics } from "../Analytics"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MakeIcon = (className) => (moreClassNames) => {
    return (<i className={className + " " + moreClassNames} aria-hidden="true"></i>)
}

const navigation = [
    { name: 'Home Page', Page: HomeRedirect, icon: "fa-solid fa-house", count: null, current: true },
    { name: 'VIP Guests', Page: VipGuests, icon: "fa-solid fa-user-crown", current: false },
    { name: 'Prices', Page: PriceControl, icon: "fa-solid fa-circle-dollar", count: null, current: false },
    { name: 'Analytics', Page: Analytics, icon: "fa-solid fa-display-chart-up", count: null, current: false },
    
    /*{ name: 'Calendar', href: '#', icon: MakeIcon("fa-solid fa-house"), count: null, current: false },
    { name: 'Documents', href: '#', icon: MakeIcon("fa-solid fa-house"), current: false },
    { name: 'Reports', href: '#', icon: MakeIcon("fa-solid fa-house"), current: false },*/
]

const teams = [
    { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
    { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
    { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]




export const Dashboard = () => {

    const store = MakeDashboardStore()
    const {
        state: {
            CurrentTabIndex,
            CurrentPage
        },
        setCurrentTab
    } = store


    useEffect(() => {
        let container = document.getElementById("headerMenuContainer")
        if(container)
        {
            container.style.display = "none"
        }

        return () => {
            if(container)
            {
                document.getElementById("headerMenuContainer").style.display = "block"
            }
        }
    },[])

    return (
        <div className="flex">
            <div>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto sc-bg-bg px-6 min-h-screen max-w-[50vw]">
                    <div className="flex h-16 shrink-0 items-center">
                        <span className="font-title text-white text-xl">Better Lottery Odds</span>
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">

                                    <CurrentTabIndex render={currentTabIndex => <>{navigation.map((item, index) => (
                                        <li key={item.name} onClick={() => setCurrentTab({index, PageComponent: item.Page})}>
                                            <div
                                                className={classNames(
                                                    currentTabIndex === index
                                                        ? 'sc-bg-success text-white cursor-default'
                                                        : 'text-themeWhite cursor-pointer hover:text-white hover:sc-bg-success',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >

                                                <i className={item.icon + " " + classNames(
                                                    currentTabIndex === index ? 'text-white' : 'text-themeWhite group-hover:text-white',
                                                    'self-center shrink-0 text-[20px]'
                                                )} aria-hidden="true"></i>

                                                {item.name}

                                                {item.count ? (
                                                    <span
                                                        className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full sc-bg-success px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-primary"
                                                        aria-hidden="true"
                                                    >
                                                        {item.count}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </li>
                                    ))}</>}/>

                                </ul>
                            </li>
                            {/*
                            <li>
                                <div className="text-xs font-semibold leading-6 text-themeWhite">Your teams</div>
                                <ul role="list" className="-mx-2 mt-2 space-y-1">
                                    {teams.map((team) => (
                                        <li key={team.name}>
                                            <a
                                                href={team.href}
                                                className={classNames(
                                                    team.current
                                                        ? 'bg-primary text-white'
                                                        : 'text-themeWhite hover:text-white hover:bg-primary',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >
                                                <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-primary bg-primary text-[0.625rem] font-medium text-white">
                                                    {team.initial}
                                                </span>
                                                <span className="truncate">{team.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            */}
                            <li className="-mx-6 mt-auto">
                                <div
                                onClick={() => authReducer.logout()}
                                    className="flex flex-col gap-x-4 px-6 py-3 text-sm leading-6 text-white cursor-pointer hover:bg-primary"
                                >
                                    <div className="font-semibold"><i className="fa-solid fa-lock pr-2"></i>Sign Out</div>
                                    <div>{authReducer.state.email}</div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="h-screen overflow-auto grow min-w-0">
                <CurrentPage render={page => <div className="p-8">
                    <page.Component />
                </div>} />
            </div>
        </div>
    )
}