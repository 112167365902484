import { PrimaryButton } from "../../../components/base/elements/input/buttons/PrimaryButton"

export const TermsModal = ({closeModal}) => {

    return (
        <div className="fixed top-0 left-0 zindex-[600] bg-black/50 w-screen h-screen flex justify-center items-center" style={{zIndex: 600}}>
            <div className="rounded-xl p-4 w-[600px] max-w-[95%] bg-white">
                <div className="w-full overflow-y-auto max-h-[60vh] text-black">
                    <h2 className="semibold text-2xl pb-4">Terms & Conditions</h2>
                    <ul>
                        <li className="p-2">Betterlotteryodds.com and/or its owners here in after referred to as “we”.</li>

                        <li className="p-2">Betterlotteryodds.com is for entertainment purposes only.</li>

                        <li className="p-2">We make no guarantee that anyone will win any lottery at any time.</li>

                        <li className="p-2">We are not responsible for digital or printing errors of any kind.</li>

                        <li className="p-2">All sales are final and sold as a one time (1) use only.</li>

                        <li className="p-2">No credit card info is ever stored or duplicated on/in our site.</li>

                        <li className="p-2">We do not share any user information with anyone for any reason.</li>

                        <li className="p-2">By clicking the accept box, you accept this terms and conditions and agree to hold</li>

                        <li className="p-2">Any or all venders, personnel or owners of Betterlotteryodds.com harmless in any case of loss of any kind.</li>
                    </ul>
                </div>
                <div className="mt-4 text-right">
                    <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
                </div>
            </div>
        </div>
    )
}