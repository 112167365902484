export default function selectFilter(index)
{
    if(index > this.MaxActiveFilter.get())
    {
        return
    }

    let currentIndex = this.ActiveFilterIndex.get()
    if(currentIndex === index)
    {
        //doNothing
        //this.ActiveFilterIndex.set(0)
    }
    else
    {
        this.ActiveFilterIndex.set(index)
    }
}