import {useState, useEffect} from 'react'

export const useValue = (val, returnComponentOnly = true) => {


    let subscribers = new Set()

    let currentValue = val

    const get = () => currentValue

    const set = (newVal, update=true) => {
        currentValue = newVal
        if(update)
        {
            subscribers.forEach(setState => setState(currentValue))
        }
    }

    const onChange = (func) => {
        subscribers.add(func)
        return () => subscribers.delete(func)
    }

    const Value = ({mapObject = null, mapArray = null, render = null, conditionalRender=null, children}) => {

        const [state, setState] = useState(get())

        useEffect(() => {
            subscribers.add(setState)
            return () => subscribers.delete(setState)
        }, [])

        if(render)
        {
            return render(state)
        }

        if(conditionalRender)
        {
            if(state)
            {
                return <>{conditionalRender(state)}</>
            }
            else
            {
                return <></>
            }
        }

        if(mapArray)
        {
            console.log("mapArray", state)
            return state.map((value, index) => mapArray(value,index))
        }

        if(mapObject)
        {
            return Object.keys(state).map((key, index) => mapObject(key,state[key],index))
        }

        if(children)
        {
            return <>{children}</>
        }

        return (
            <>{state}</>
        )
    }


    if(returnComponentOnly)
    {
        Value.get = get
        Value.set = set
        Value.onChange = onChange
        return Value
    }
    else
    {
        return {Value, get, set, onChange}
    }
}