import { saveAs } from 'file-saver'
import api from '../../../../utils/api'
import authReducer from '../../../../App/reducers/auth'

export default async function downloadFile({ orderId = null, isNewVip = false } = {}) {
    this.DownloadingFile.set(true)

    try {
        if (this.SelectedFileOutputType.get() === "TEXT") {
            if (authReducer.state.accountType === "SUPER_ADMIN" || authReducer.state.accountType === "VIP_GUEST" || orderId !== null || this.PriceSettings.get().enablePayments === false) {
                let { data } = await api.post('/lottery/download/text-file', {
                    filterSelections: this.FilterSelections.get(),
                    fileDownloadFilters: this.FileDownloadFilters.get(),
                    orderId,
                    isNewVip
                }, { responseType: 'blob' })

                saveAs(data, "lottery_numbers.txt")

                this.ShowDownloadCompleteMessage.set(true)
            }
            else {
                this.IsPaypalPromptOpen.set(true)
            }
        }
        else if (this.SelectedFileOutputType.get() === "PRINT") {
            if (authReducer.state.accountType === "SUPER_ADMIN" || authReducer.state.accountType === "VIP_GUEST" || orderId !== null || this.PriceSettings.get().enablePayments === false) {
                let { data } = await api.post('/lottery/download/pdf-file', {
                    filterSelections: this.FilterSelections.get(),
                    fileDownloadFilters: this.FileDownloadFilters.get(),
                    orderId,
                    isNewVip
                }, { responseType: 'blob' })

                saveAs(data, "lottery_cards.pdf")

                this.ShowDownloadCompleteMessage.set(true)
            }
            else {
                this.IsPaypalPromptOpen.set(true)
            }
        }
        else {
            throw new Error("No valid file type selected.")
        }
    }
    catch (err) {
        console.log(err)
        alert(err.message)
    }

    this.DownloadingFile.set(false)
}