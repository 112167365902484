import { useNumberCombinationStore } from "../store/NumberCombination"
import { SingleDigit } from "./SingleDigit"
import { NumberContainer, NumberSectionColumn } from "./Style"

export const NumberDisplay = () => {

    const {
        state: {
            Numbers,
            IsComputing
        }
    } = useNumberCombinationStore()

    return (
        <div className="flex justify-center pb-8 mt-0 sm:mt-4">
            <NumberSectionColumn>
                <div className="font-title text-2xl text-primary pl-2 my-4">
                    Number of Combinations
                    <IsComputing conditionalRender={isComputing => <span className="text-base">&nbsp;(Computing...)</span>}/>
                </div>
                <NumberContainer>
                    <Numbers render={numbers => <>
                        <SingleDigit currentDigit={numbers[0]}/>
                        <SingleDigit currentDigit={numbers[1]}/>
                        <SingleDigit currentDigit={numbers[2]}/>
                        <SingleDigit currentDigit={numbers[3]}/>
                        <SingleDigit currentDigit={numbers[4]}/>
                        <SingleDigit currentDigit={numbers[5]}/>
                        <SingleDigit currentDigit={numbers[6]}/>
                        <SingleDigit currentDigit={numbers[7]}/>
                    </>}/>
                </NumberContainer>
            </NumberSectionColumn>
        </div>
    )
}