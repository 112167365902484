import styled from "styled-components";

export const Container = styled.div`
    width: 400px;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: auto;
    margin-top: 32px;
`

/**
 * props: selected (bool)
 */
export const NumberSelector = styled.div`
    display: inline-block;
    width: 100%;
    background-color: ${props => props.selected ? props.theme.colors.primary() : props.theme.colors.bg(10, -10)};
    padding: 8px;
    min-height: 64px;
    border-radius: 7px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    cursor: ${props => props.selected ? 'default' : 'pointer'};
    animation: ${props => props.selected ? 'fade-selected' : 'fade-unselected'} 1s ease;


    &:hover {
        background-color: ${props => props.selected ? props.theme.colors.primary() : props.theme.colors.bg(15, -5)};
    }

    @keyframes fade-unselected {
        0% {
            background-color: ${props => props.theme.colors.primary()}
        }
        100% {
            background-color: ${props => props.theme.colors.bg(10, -10)};
        }
    }

    @keyframes fade-selected {
        0% {
            background-color: ${props => props.theme.colors.bg(10, -10)}
        }
        100% {
            background-color: ${props => props.theme.colors.primary()};
        }
    }
`

export const CallToActionButton = styled.p`
    display: inline-block;
    text-align: center;
    color: ${props => props.disabled ? "gray" : props.theme.colors.white()};
    /*text-decoration: underline;*/
    cursor: pointer;
    border-bottom: 1px solid ${props => props.disabled ? "gray" : props.theme.colors.white()};
    width: fit-content;
    margin: auto;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};

    &:hover {
        color: ${props => props.disabled ? "gray" : props.theme.colors.primary()};
    }
`

export const ErrorBanner = styled.div`
    background-color: ${props => props.theme.colors.error()};
    color: ${props => props.theme.colors.white()};
    padding: 8px 16px;
    border-radius: 7px;
`