import { useEffect } from "react"
import { sendPageView } from "."

export const TrackPage = ({children}) => {

    useEffect(() => {
        sendPageView()
    }, [])

    return (
        <>
            {children}
        </>
    )
}