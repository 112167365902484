import styled from "styled-components";

export const PaypalButtonsContainer = styled.div`
    background-color: ${props => props.theme.colors.white(50,-5)};
    border-radius: 9px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    padding: 32px;
    width: 400px;
    max-width: 100%;
    margin: auto;
`