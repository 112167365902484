import styled from 'styled-components'

export const HeroWrapper = styled.div`
    background-color: ${props => props.theme.colors.black(0,0)};
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
`
export const HeroContainer = styled.div`
    position: absolute;
    display: block;
    height: 100vh;
    width: 100%;
    background-image: url(/images/heroForDesktop.jpg);
    background-position: 75%;
    background-size: cover;
    opacity:0;
    animation:fade-in 1.5s;
    animation-delay:0.5s;
    -webkit-animation:fade-in 1.5s;
    -webkit-animation-delay:0.5s;
    -o-animation:fade-in 1.5s;
    -o-animation-delay:0.5s;
    animation-fill-mode: forwards;

    @keyframes fade-in {
        from { opacity:0;}
        to {opacity:1;}
    }

    @-webkit-keyframes fade-in {
        from {opacity:0;}
        to {opacity:1;}
    }
`

export const FilterSectionBody = styled.div`
    position: relative;
    display: block;
    min-height: 100vh;
    z-index: 1;
    background-color: ${props => props.theme.colors.bg(0,0)};
    color: white;
    margin-top: 0px;
    box-shadow: 1px -5px 15px 0px rgba(0,0,0,0.75);
`

export const Title_Better = styled.div`
    font-size: 64px;
    font-weight: 400;
    color: ${props => props.theme.colors.white(0,0)};
    padding: 0px 32px;
    border-radius: 9px;
    background-color: ${props => props.theme.colors.error(0,0)};
    display: inline-block;
    margin-left: 32px;
    font-family: "Agbalumo", Arial, Helvetica, sans-serif;
    animation: slide-1 1.5s ease;

    @keyframes slide-1 {
        0% {
            opacity: 0;
            transform: translateX(50px);
        }
        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    }

    @media screen and (max-width: 700px) {
        font-size: 32px;
    }
`

export const Title_LotterOdds = styled.div`
    margin-top: 12px;
    font-size: 48px;
    font-weight: 400;
    color: ${props => props.theme.colors.error(0,0)};
    padding: 8px 64px;
    background-color: ${props => props.theme.colors.white(0,0)};
    border-radius: 7px;
    display: inline-block;
    font-family: "Agbalumo", Arial, Helvetica, sans-serif;
    animation: slide-2 1.5s ease;

    @keyframes slide-2 {
        0% {
            opacity: 0;
            transform: translateX(-50px);
        }
        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    }

    @media screen and (max-width: 700px) {
        font-size: 24px;
    }
`

export const PageMargin = styled.div`
    position: relative;
    z-index: 2;
    margin-top: calc(100vh - 256px);

    @media screen and (max-width: 700px) {
        margin-top: calc(100vh - 130px);
    }
`

export const TitleHolder = styled.div`
    margin-left: 64px;
    margin-bottom: 24px;
`

//https://codepen.io/jmuspratt/pen/zYddQQ
export const AnimatedArrowContainer = styled.div`
@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    background-position: center top;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    -moz-background-size: 75% auto;
    -o-background-size: 75% auto;
    -webkit-background-size: 75% auto;
    background-size: 75% auto;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    background-position: center bottom;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 0;
    background-position: center top;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    -moz-background-size: 75% auto;
    -o-background-size: 75% auto;
    -webkit-background-size: 75% auto;
    background-size: 75% auto;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    background-position: center bottom;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
}
@-ms-keyframes pulse {
  0% {
    opacity: 0;
    background-position: center top;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    -moz-background-size: 75% auto;
    -o-background-size: 75% auto;
    -webkit-background-size: 75% auto;
    background-size: 75% auto;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    background-position: center bottom;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
    background-position: center top;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    -moz-background-size: 75% auto;
    -o-background-size: 75% auto;
    -webkit-background-size: 75% auto;
    background-size: 75% auto;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    background-position: center bottom;
    -moz-background-size: 0 auto;
    -o-background-size: 0 auto;
    -webkit-background-size: 0 auto;
    background-size: 0 auto;
  }
}

@keyframes arrow-fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.scroll-down {
  margin: 0;
  /*border: 2px solid #fff;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;*/
  position: absolute;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  transform: scale(0.7);
  animation: arrow-fadein 3s ease;
}
.scroll-down a {
  display: block;
  opacity: 0;
  /*-moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;*/
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  line-height: 0;
  font-size: 0;
  color: transparent;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  -moz-background-size: 0 auto;
  -o-background-size: 0 auto;
  -webkit-background-size: 0 auto;
  background-size: 0 auto;
  -webkit-animation: pulse 1.5s 0s infinite normal ease forwards;
  -moz-animation: pulse 1.5s 0s infinite normal ease forwards;
  -o-animation: pulse 1.5s 0s infinite normal ease forwards;
  animation: pulse 1.5s 0s infinite normal ease forwards;
  background-image: url("/images/animatedArrowDown.svg");
  background-repeat: no-repeat;
}
.scroll-down a:before, .scroll-down a:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: url("/images/animatedArrowDown.svg") no-repeat center top;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}
.scroll-down a:before {
  -webkit-animation: pulse 1.5s 0.25s infinite normal ease forwards;
  -moz-animation: pulse 1.5s 0.25s infinite normal ease forwards;
  -o-animation: pulse 1.5s 0.25s infinite normal ease forwards;
  animation: pulse 1.5s 0.25s infinite normal ease forwards;
}
.scroll-down a:after {
  -webkit-animation: pulse 1.5s 0.5s infinite normal ease forwards;
  -moz-animation: pulse 1.5s 0.5s infinite normal ease forwards;
  -o-animation: pulse 1.5s 0.5s infinite normal ease forwards;
  animation: pulse 1.5s 0.5s infinite normal ease forwards;
}
`

export const Container1280 = styled.div`
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px;
    padding-top: 64px;
`

export const LotteryIconCombo = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  animation: fade-in 1.5s;
  max-width: calc(100vw - 100px);

  @keyframes fade-in {
      from { opacity:0;}
      to {opacity:1;}
  }

  @media screen and (max-width: 500px) {
    left: calc(50% - 170px);
  }
`

export const AffiliateWarning = styled.div`
  padding: 8px 16px;
  color: ${props => props.theme.colors.white()};
  background-color: ${props => props.theme.colors.black(5,5)};
  opacity: 0.9;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`

