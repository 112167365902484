import styled from 'styled-components'
import { useLotteryFiltersStore } from '../../store'
import { useValue } from '../../../../components/base/hooks/useValue'
import { ApplyFilterButton, Tooltip } from '../Style'

/** props
 * isSelected (boolean)
 */
const Ball = styled.div`
    display: flex;
    width: 20px;
    height: 20px;
    background-color: ${props => props.isSelected ? props.theme.colors.error() : 'transparent'};
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.secondary()};
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
    ${props => props.isSelected ? 'color: '+props.theme.colors.white()+' !important;' : ''}
    cursor: pointer;
`

const ApplyButton = styled(ApplyFilterButton)`
    padding: 2px 8px;
    font-size: 14px;
`

export const PowerBallFilterBody = () => {

    const {
        state: {
            FilterSelections
        },
        selectPowerBalls
    } = useLotteryFiltersStore()

    let Selection = useValue(FilterSelections.get().lottery === "Powerball" ? new Array(26).fill(false) : new Array(25).fill(false))
    let ErrorMessage = useValue(null)

    const selectBall = (index) => {
        let newSelection = [...Selection.get()]
        newSelection[index] = !newSelection[index]
        Selection.set(newSelection)
        ErrorMessage.set(null)
    }

    const applySelection = () => {
        let selectedBalls = []
        Selection.get().forEach((isSelected, index) => {
            if (isSelected) selectedBalls.push(index+1)
        })
        if(selectedBalls.length < 1)
        {
            ErrorMessage.set("You must select at least one ball")
            return
        }
        selectPowerBalls(selectedBalls)
    }

    const selectAll = () => {
        let selectedBalls = []
        Selection.get().forEach((isSelected, index) => {
            selectedBalls.push(index+1)
        })
        selectPowerBalls(selectedBalls)
    }


    return (
        <div className='relative'>
            <div className="flex flex-wrap gap-1 p-1">
                <Selection render={selection => <>
                    {selection.map((isSelected, index) => 
                        <Ball key={"ball_"+index} onClick={() => selectBall(index)} isSelected={isSelected}>{index+1}</Ball>
                    )}
                </>} />
            </div>
            <div className='absolute top-full pl-1 -mt-[6px]'>
                <ErrorMessage render={message => <>
                    {message === null &&
                        <ApplyButton onClick={applySelection}>
                            Select
                        </ApplyButton>
                    }
                    {message !== null &&
                        <ApplyButton error={true}>
                            Select
                            <Tooltip top="35px" indicatorLeft="20px" style={{width: '200px'}}>
                                {message}
                            </Tooltip>
                        </ApplyButton>
                    }
                </>} />
                <ApplyButton className="ml-2" onClick={selectAll}>All</ApplyButton>
            </div>
        </div>
    )
}