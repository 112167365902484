import { useSelector } from "react-redux"
import { setAuthToken } from "../../utils/api"


const makeAuthReducer = () => {
    const code = "auth"
    let dispatch = () => null
    let state = {
      _id: null,
      accountType: null,
      email: null,
      exp: null, //jwt "exp" parameter which is expiration time in unicode,
      loaded: false,
      isAuthenticated: false
    }
  
    const redux = (newState = state, {type}) => {
        if(type == code)
        {
            return {...state}
        }
  
        return newState
    }
  
    const setDispatch = (func) => {
        dispatch = () => func({"type":code})
    }

    const Use = () => {
      return useSelector(state => state["auth"])
    }
  
    const loadUser = (userData) => {
      state = {
        ...state,
        accountType: userData.accountType || null,
        email: userData.email || null,
        isAuthenticated: true,
        loaded: true,
        _id: userData._id || null,
      }
      dispatch()
    }
  
  
    const logout = () => {
      state.accountType = null
      state.isAuthenticated = false
      state.loaded = true
      state._id = null
      dispatch()
      localStorage.removeItem('token')
    }
  
    return {state, redux, Use, setDispatch, 
      loadUser,
      logout
    }
  }

  class AuthReducer
  {
    constructor()
    {
      this.code = "auth"
      this.dispatch = () => null
      this.state = {
        _id: null,
        accountType: null,
        email: null,
        exp: null, //jwt "exp" parameter which is expiration time in unicode,
        loaded: false,
        isAuthenticated: false
      }
    }

    redux(newState = null, {type})
    {
      if(newState === null)
      {
        newState = this.state
      }
      
      if(type === this.code)
      {
          return {...this.state}
      }

      return newState
    }

    setDispatch(func)
    {
      this.dispatch = () => func({"type":this.code})
    }

    loadUser(userData)
    {
      this.state = {
        ...this.state,
        accountType: userData.accountType || null,
        email: userData.email || null,
        isAuthenticated: true,
        loaded: true,
        _id: userData._id || null,
      }
      this.dispatch()
    }

    logout()
    {
      this.state.accountType = null
      this.state.isAuthenticated = false
      this.state.loaded = true
      this.state._id = null
      this.dispatch()
      localStorage.removeItem('token')
      setAuthToken(null)
    }
  }
  
  export const authReducer = new AuthReducer() //makeAuthReducer()
  export const useAuthReducer = () => useSelector(state => state[authReducer.code])
  
  export default authReducer