import api from "../../../../utils/api"

export default async function setVipAccountPrice()
{
    try
    {
        await api.patch('/price-control/values', {
            vipAccountPrice: this.VipAccountPrice.get()
        })

        alert("Your new price was saved!")
    }
    catch (err)
    {
        console.log(err)
        alert("Failed to change 'Vip Account Price' value")
    }
}