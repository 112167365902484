import { Checkbox } from "../../../components/base/elements/input/Checkbox"
import { useLotteryFiltersStore } from "../store"

export const ExtraOptionsSelector = () => {

    const {
        state: {
            FilterSelections,
            FileDownloadFilters,
            NumberCombinationStore
        },
        setNumberOfCards
    } = useLotteryFiltersStore()

    const onSpecial1Change = (e) => {
        FileDownloadFilters.set({ ...FileDownloadFilters.get(), special1: e.target.checked })
    }

    const onSpecial2Change = (e) => {
        FileDownloadFilters.set({ ...FileDownloadFilters.get(), special2: e.target.checked })
    }

    const doublePlayEnableMap = {
        "FL": true,
        "GA": false,
        "IL": false,
        "IN": true,
        "IA": true,
        "KY": false,
        "MI": true,
        "OH": false,
        "TN": true
    }

    return (

        <div className="flex justify-around">
            <div><Checkbox size="25px" onChange={onSpecial1Change} /><span className="text-[25px] align-super ml-2">{FilterSelections.get().lottery === "Powerball" ? 'Power Play' : "Megaplier"}</span></div>

            <FileDownloadFilters render={fileDownloadFilters => <>
                {FilterSelections.get().lottery === "Powerball" && doublePlayEnableMap[fileDownloadFilters.state] &&
                    <div><Checkbox size="25px" onChange={onSpecial2Change} />
                        <span className="text-[25px] align-super ml-2">Double Play</span></div>
                }
            </>} />
        </div>
    )
}