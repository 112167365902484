import { useLotteryFiltersStore } from "../../store"
import { FilterBodySmallButton } from "../Style"

export const EvenlySpacedFilterBody = () => {
    const {
        state: {
            FilterSelections
        },
        applyEvenlySpacedNumbersFilter
    } = useLotteryFiltersStore()

    return (
        <div className="mt-2 flex align-middle justify-center gap-4">
            <FilterBodySmallButton onClick={() => applyEvenlySpacedNumbersFilter(true)}>Keep</FilterBodySmallButton>
            <FilterBodySmallButton onClick={() => applyEvenlySpacedNumbersFilter(false)}>Remove</FilterBodySmallButton>
        </div>
    )
}