export const themeSettings = () => ({
    colors: {
        /*
        primary: (hueOffset=0, brightnessOffset=0) => `hsl(17, ${Math.max(0,Math.min(100,92+hueOffset))}%, ${Math.max(0,Math.min(100,58+brightnessOffset))}%)`,
        secondary: (hueOffset=0, brightnessOffset=0) => `hsl(355, ${Math.max(0,Math.min(100,67+hueOffset))}%, ${Math.max(0,Math.min(100,33+brightnessOffset))}%)`,
        warning: (hueOffset=0, brightnessOffset=0) => `hsl(41, ${Math.max(0,Math.min(100,98+hueOffset))}%, ${Math.max(0,Math.min(100,80+brightnessOffset))}%)`,
        bg: (hueOffset=0, brightnessOffset=0) => `hsl(352, ${Math.max(0,Math.min(100,100+hueOffset))}%, ${Math.max(0,Math.min(100,15+brightnessOffset))}%)`,
        black: (hueOffset=0, brightnessOffset=0) => `hsl(216, ${Math.max(0,Math.min(100,100+hueOffset))}%, ${Math.max(0,Math.min(100,3+brightnessOffset))}%)`,
        white: (hueOffset=0, brightnessOffset=0) => `hsl(68, ${Math.max(0,Math.min(100,52+hueOffset))}%, ${Math.max(0,Math.min(100,94+brightnessOffset))}%)`,
        error: (hueOffset=0, brightnessOffset=0) => `hsl(356, ${Math.max(0,Math.min(100,94+hueOffset))}%, ${Math.max(0,Math.min(100,51+brightnessOffset))}%)`,
        success: (hueOffset=0, brightnessOffset=0) => `hsl(140, ${Math.max(0,Math.min(100,33+hueOffset))}%, ${Math.max(0,Math.min(100,46+brightnessOffset))}%)`
        */

        
        /*
        primary: (hueOffset=0, brightnessOffset=0) => `hsl(142, ${Math.max(0,Math.min(100,97+hueOffset))}%, ${Math.max(0,Math.min(100,37+brightnessOffset))}%)`,
        //secondary: (hueOffset=0, brightnessOffset=0) => `hsl(203, ${Math.max(0,Math.min(100,96+hueOffset))}%, ${Math.max(0,Math.min(100,31+brightnessOffset))}%)`,
        secondary: (hueOffset=0, brightnessOffset=0) => `hsl(203, ${Math.max(0,Math.min(100,96+hueOffset))}%, ${Math.max(0,Math.min(100,20+brightnessOffset))}%)`,
        warning: (hueOffset=0, brightnessOffset=0) => `hsl(54, ${Math.max(0,Math.min(100,98+hueOffset))}%, ${Math.max(0,Math.min(100,47+brightnessOffset))}%)`,
        //bg: (hueOffset=0, brightnessOffset=0) => `hsl(42, ${Math.max(0,Math.min(100,7+hueOffset))}%, ${Math.max(0,Math.min(100,63+brightnessOffset))}%)`,
        bg: (hueOffset=0, brightnessOffset=0) => `hsl(42, ${Math.max(0,Math.min(100,7+hueOffset))}%, ${Math.max(0,Math.min(100,80+brightnessOffset))}%)`,
        black: (hueOffset=0, brightnessOffset=0) => `hsl(208, ${Math.max(0,Math.min(100,76+hueOffset))}%, ${Math.max(0,Math.min(100,5+brightnessOffset))}%)`,
        white: (hueOffset=0, brightnessOffset=0) => `hsl(330, ${Math.max(0,Math.min(100,5+hueOffset))}%, ${Math.max(0,Math.min(100,92+brightnessOffset))}%)`,
        error: (hueOffset=0, brightnessOffset=0) => `hsl(293, ${Math.max(0,Math.min(100,34+hueOffset))}%, ${Math.max(0,Math.min(100,55+brightnessOffset))}%)`,
        success: (hueOffset=0, brightnessOffset=0) => `hsl(142, ${Math.max(0,Math.min(100,97+hueOffset))}%, ${Math.max(0,Math.min(100,37+brightnessOffset))}%)`,
        cardText: (hueOffset=0, brightnessOffset=0) => `hsl(293, ${Math.max(0,Math.min(100,34+hueOffset))}%, ${Math.max(0,Math.min(100,55+brightnessOffset))}%)`
        */


        primary: (hueOffset=0, brightnessOffset=0) => `hsl(17, ${Math.max(0,Math.min(100,92+hueOffset))}%, ${Math.max(0,Math.min(100,58+brightnessOffset))}%)`,
        secondary: (hueOffset=0, brightnessOffset=0) => `hsl(355, ${Math.max(0,Math.min(100,67+hueOffset))}%, ${Math.max(0,Math.min(100,33+brightnessOffset))}%)`,
        warning: (hueOffset=0, brightnessOffset=0) => `hsl(41, ${Math.max(0,Math.min(100,98+hueOffset))}%, ${Math.max(0,Math.min(100,80+brightnessOffset))}%)`,
        bg: (hueOffset=0, brightnessOffset=0) => `hsl(203, ${Math.max(0,Math.min(100,96+hueOffset))}%, ${Math.max(0,Math.min(100,20+brightnessOffset))}%)`,
        black: (hueOffset=0, brightnessOffset=0) => `hsl(216, ${Math.max(0,Math.min(100,100+hueOffset))}%, ${Math.max(0,Math.min(100,3+brightnessOffset))}%)`,
        white: (hueOffset=0, brightnessOffset=0) => `hsl(68, ${Math.max(0,Math.min(100,52+hueOffset))}%, ${Math.max(0,Math.min(100,94+brightnessOffset))}%)`,
        error: (hueOffset=0, brightnessOffset=0) => `hsl(356, ${Math.max(0,Math.min(100,94+hueOffset))}%, ${Math.max(0,Math.min(100,31+brightnessOffset))}%)`,
        success: (hueOffset=0, brightnessOffset=0) => `hsl(140, ${Math.max(0,Math.min(100,33+hueOffset))}%, ${Math.max(0,Math.min(100,46+brightnessOffset))}%)`,
        cardText: (hueOffset=0, brightnessOffset=0) => `hsl(68, ${Math.max(0,Math.min(100,52+hueOffset))}%, ${Math.max(0,Math.min(100,94+brightnessOffset))}%)`,
    }
})