import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useValue } from '../../components/base/hooks/useValue'
import { Loader } from '../../components/base/elements/Loader'
import api from '../../utils/api'
import { useVipGuestStore } from './store'

export const AddGuestModal = ({ OpenState }) => {

    const {
        addVipGuest
    } = useVipGuestStore()

    const IsSaving = useValue(false)

    const cancelButtonRef = useRef(null)
    const emailRef = useRef()
    const passwordRef = useRef()

    const onSubmit = async (e) => {
        e.preventDefault()

        IsSaving.set(true)

        try
        {
            await addVipGuest({
                email: emailRef.current.value,
                password: passwordRef.current.value
            })

            OpenState.set(false)
        }
        catch(err)
        {
            console.log(err)
            alert(err.message)
        }

        IsSaving.set(false)
        console.log(emailRef.current.value, passwordRef.current.value)
    }

    return (
        <OpenState render={open => <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => OpenState.set(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <form className="space-y-6" action="#" method="POST" onSubmit={onSubmit}>
                                        <div>
                                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                                <i class="fa-solid fa-user-crown text-3xl"></i>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    New VIP Guest
                                                </Dialog.Title>
                                                <div className="mt-2">

                                                    <div className='mb-2'>
                                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-secondary text-left">
                                                            Email address
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="email"
                                                                ref={emailRef}
                                                                name="email"
                                                                type="email"
                                                                autoComplete="email"
                                                                required
                                                                className="block w-full rounded-md border-0 bg-secondary/5 py-1.5 px-2 text-secondary shadow-sm ring-1 ring-inset ring-primary/10 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="flex items-center justify-between">
                                                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-secondary">
                                                                Password
                                                            </label>
                                                        </div>
                                                        <div className="mt-2">
                                                            <input
                                                                id="password"
                                                                ref={passwordRef}
                                                                name="password"
                                                                type="text"
                                                                autoComplete="current-password"
                                                                required
                                                                className="block w-full rounded-md border-0 bg-secondary/5 py-1.5 px-2 text-secondary shadow-sm ring-1 ring-inset ring-primary/10 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <IsSaving render={isSaving => <>
                                            {!isSaving &&
                                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                    <button
                                                        type="submit"
                                                        className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary sm:col-start-2"
                                                    >
                                                        Add Guest
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                        onClick={() => OpenState.set(false)}
                                                        ref={cancelButtonRef}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            }
                                            {isSaving &&
                                                <Loader size="34px"/>
                                            }
                                        </>}/>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>} />
    )
}