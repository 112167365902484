import { useEffect } from "react"
import { MakeAnalyticsStore } from "./store"

export const Analytics = () => {

    const store = MakeAnalyticsStore()
    

    const {
        state: {
            HitCounter
        },
        load
    } = store


    useEffect(() => {
        load()
    }, [])

    return (
        <h2>
            Hit Counter : <HitCounter />
        </h2>
    )
}