import api from "../../../../utils/api"

export default async function deleteGuest(guestId)
{
    try
    {
        let {data} = await api.delete(`/vip-guests/${guestId}`)

        let newGuests = this.VipGuests.get().filter(guest => guest._id.toString() !== guestId.toString())
        this.VipGuests.set(newGuests)
    }
    catch (err)
    {
        console.log(err)
        alert(err.message)
    }
}