import { useValue } from "../../../components/base/hooks/useValue"
import { VipGuests } from "../../VipGuests"
import setCurrentTab from "./functions/setCurrentTab"

export const MakeDashboardStore = () => {
    const state = {
        CurrentTabIndex: useValue(1),
        CurrentPage: useValue({Component: VipGuests})
    }

    return {
        state,
        setCurrentTab: setCurrentTab.bind(state)
    }
}