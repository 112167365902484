

export const ContactUsSection = () => {
    return (
        <div className="text-center my-4 w-[1200px] max-w-[100%] mx-auto text-lg">
            <div className="max-w-[95%] inline-block">
                If you have any questions, please send us en email at &nbsp;
                <a href="mailto:info@betterlotteryodds.com" className="font-semibold cursor-pointer text-primary">
                    info@betterlotteryodds.com
                </a>
            </div>
        </div>
    )
}