import { createContext, useContext } from "react"
import { useValue } from "../../../components/base/hooks/useValue"
import load from "./functions/load"
import addVipGuest from "./functions/addVipGuest"
import { useValueRef } from "../../../components/base/hooks/useValueRef"
import deleteGuest from "./functions/deleteGuest"

export const VipGuestStoreContext = createContext(null)

export const useVipGuestStore = () => useContext(VipGuestStoreContext)


export const MakeVipGuestsStore = () => {
    const state = {
        VipGuests: useValue([]),
    }

    return {
        state,
        load: load.bind(state),
        addVipGuest: addVipGuest.bind(state),
        deleteGuest: deleteGuest.bind(state)
    }
}