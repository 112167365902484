import { useSelector } from "react-redux"
import { Loader } from "../../../components/base/elements/Loader"
import { Checkbox } from "../../../components/base/elements/input/Checkbox"
import { useValue } from "../../../components/base/hooks/useValue"
import { PaymentSection } from "../PaymentSection"
import { useLotteryFiltersStore } from "../store"
import { CalibrationSection } from "./CalibrationSection"
import { ExtraOptionsSelector } from "./ExtraOptionsSelector"
import { ForBetterResultsMessage } from "./ForBetterResultsMessage"
import { IntervalSelector } from "./IntervalSelector"
import { NumberOfCardsSelector } from "./NumberOfCardsSelector"
import { NumberOfTicketsSelector } from "./NumberOfTicketsSelector"
import { PrinterStyleSelector } from "./PrinterStyleSelector"
import { StateSelector } from "./StateSelector"
import { CallToActionButton, Container, ErrorBanner, NumberSelector } from "./Style"
import { TermsModal } from "./TermsModal"

export const TicketSelectorForFile = () => {

    const {
        state: {
            SelectedFileOutputType,
            DownloadingFile,
            IsPaypalPromptOpen,
            ShowDownloadCompleteMessage,
            FilterSelections,
            //CalibrationObject,
            PriceSettings
        },
        downloadFile,
        setIsVipOptionSelected
    } = useLotteryFiltersStore()

    const auth = useSelector(state => state.auth)

    const DownloadError = useValue(null)
    const TermsError = useValue(null)
    const TermsModalOpen = useValue(false)
    const TermsAgreed = useValue(false)

    const onDownloadClick = async ({ newVip = false } = {}) => {

        {/* 
        if (SelectedFileOutputType.get() === "PRINT" && !CalibrationObject.get()) {
            DownloadError.set("You must go through the calibration step first. That way your tickets will be properly filled.")
            return
        }
        */}
        if (TermsAgreed.get()) {
            if (TermsError.get()) {
                TermsError.set(null)
            }
        }
        else {
            TermsError.set("You must first agree to the terms and conditions.")

            return
        }

        setIsVipOptionSelected(newVip)

        try {
            await downloadFile()
        }
        catch (err) {
            console.log(err)
            alert(err.message)
        }
    }

    const openTermsAndConditionModal = () => {
        TermsModalOpen.set(true)
    }

    return (
        <Container className="pb-20">
            <SelectedFileOutputType render={type => <>

                <TermsModalOpen conditionalRender={isOpen => <>
                    <TermsModal closeModal={() => TermsModalOpen.set(false)} />
                </>} />

                {type === "TEXT" &&
                    <>
                        <FilterSelections render={filter => <>
                            {filter.selectedPowerBalls.length > 0 ?
                                <>
                                    <IntervalSelector />

                                    <NumberOfTicketsSelector />

                                    <IsPaypalPromptOpen render={isOpen => <>
                                        {isOpen &&
                                            <PaymentSection />
                                        }
                                        {!isOpen &&
                                            <DownloadingFile render={downloading => <>
                                                {downloading &&
                                                    <Loader />
                                                }
                                                {!downloading &&
                                                    <>
                                                        <div className="mt-3">

                                                            <TermsAgreed render={termsAgreed => <>
                                                                <Checkbox checked={termsAgreed} onClick={e => TermsAgreed.set(e.target.checked)} />
                                                            </>} />&nbsp;
                                                            {PriceSettings.get().enablePayments === true ? 'By paying for your numbers' : 'By downloading your numbers'}, you agree to the <strong className="text-primary cursor-pointer hover:underline" onClick={openTermsAndConditionModal}>terms & conditions</strong> of betterlotteryodds.com
                                                            <TermsError conditionalRender={error => <>
                                                                <ErrorBanner className="mt-1">{error}</ErrorBanner>
                                                            </>} />
                                                        </div>

                                                        <ShowDownloadCompleteMessage conditionalRender={show => <div className="text-primary text-lg my-6 py-2 px-4 border-white border rounded-xl">Make sure you properly save the file or check your download folder!</div>} />

                                                        {PriceSettings.get().enablePayments === true && auth.isAuthenticated == false &&
                                                            <CallToActionButton className="mt-0 text-xl" onClick={onDownloadClick}>Download text file for <strong>${PriceSettings.get().txtFilePrice}</strong> USD</CallToActionButton>
                                                        }
                                                        {(PriceSettings.get().enablePayments === false || auth.isAuthenticated == true) &&
                                                            <CallToActionButton className="mt-0 text-xl" onClick={onDownloadClick}>Download text file</CallToActionButton>
                                                        }



                                                        {PriceSettings.get().enablePayments === true && auth.isAuthenticated == false && <>
                                                            <div className="flex justify-center text-primary text-lg font-bold"> OR </div>
                                                            <CallToActionButton className="mt-0 text-xl" onClick={() => onDownloadClick({ newVip: true })}>* Become Vip supporter for <strong>${PriceSettings.get().vipAccountPrice}</strong> USD</CallToActionButton>
                                                            <div className="max-w-[350px] m-auto">* Vip supporters can download unlimited files for one year. Your account will be activated within 24h of your payment and you will receive an email.</div>
                                                        </>}

                                                    </>
                                                }
                                            </>} />
                                        }
                                    </>} />
                                </>
                                :
                                <>
                                    <p className="bg-error rounded text-center text-themeWhite font-semibold">Please fill in all your filters before using this option</p>
                                </>
                            }
                        </>} />
                    </>
                }
                {type === "PRINT" &&
                    <>
                        <div className="m-auto">
                            <div className="inline-block m-auto">
                                <div className="w-[400px] max-w-[95vw]">Example:</div>
                                <img src="/images/cardExample.png" className="max-w-[95vw]" />
                            </div>
                        </div>
                        <StateSelector />
                        <FilterSelections render={filter => <>
                            {filter.selectedPowerBalls.length > 0 ?
                                <>
                                    {/*<PrinterStyleSelector />*/}

                                    <IntervalSelector />

                                    <NumberOfTicketsSelector />

                                    <NumberOfCardsSelector />

                                    <ExtraOptionsSelector />

                                    {/*<CalibrationSection DownloadError={DownloadError} />*/}

                                    <IsPaypalPromptOpen render={isOpen => <>
                                        {isOpen &&
                                            <PaymentSection />
                                        }
                                        {!isOpen &&
                                            <DownloadingFile render={downloading => <>
                                                {downloading &&
                                                    <Loader />
                                                }
                                                {!downloading &&
                                                    <>
                                                        <DownloadError conditionalRender={error => <>
                                                            <ErrorBanner>{error}</ErrorBanner>
                                                        </>} />
                                                        <div className="mt-3">

                                                            <TermsAgreed render={termsAgreed => <>
                                                                <Checkbox checked={termsAgreed} onClick={e => TermsAgreed.set(e.target.checked)} />
                                                            </>} />&nbsp;
                                                            {PriceSettings.get().enablePayments === true ? 'By paying for your numbers' : 'By downloading your numbers'}, you agree to the <strong className="text-primary cursor-pointer hover:underline" onClick={openTermsAndConditionModal}>terms & conditions</strong> of betterlotteryodds.com
                                                            <TermsError conditionalRender={error => <>
                                                                <ErrorBanner className="mt-1">{error}</ErrorBanner>
                                                            </>} />
                                                        </div>

                                                        <ShowDownloadCompleteMessage conditionalRender={show => <div className="text-primary text-lg my-6 py-2 px-4 border-white border rounded-xl">Make sure you properly save the file or check your download folder!</div>} />

                                                        <FilterSelections render={filter => <>



                                                            {PriceSettings.get().enablePayments === true && auth.isAuthenticated == false &&
                                                                <CallToActionButton disabled={!(filter.selectedPowerBalls.length > 0)} className="mt-3 text-xl" onClick={onDownloadClick}>Download pdf file for <strong>${PriceSettings.get().pdfFilePrice}</strong> USD</CallToActionButton>
                                                            }
                                                            {(PriceSettings.get().enablePayments === false || auth.isAuthenticated == true) &&
                                                                <CallToActionButton disabled={!(filter.selectedPowerBalls.length > 0)} className="mt-3 text-xl" onClick={onDownloadClick}>Download pdf file</CallToActionButton>
                                                            }
                                                        </>} />

                                                        {PriceSettings.get().enablePayments === true && auth.isAuthenticated == false && <>
                                                            <div className="flex justify-center text-primary text-lg font-bold"> OR </div>
                                                            <CallToActionButton className="mt-0 text-xl" onClick={() => onDownloadClick({ newVip: true })}>* Become Vip supporter for <strong>${PriceSettings.get().vipAccountPrice}</strong> USD</CallToActionButton>
                                                            <div className="max-w-[350px] m-auto">* Vip supporters can download unlimited files for one year. Your account will be activated within 24h of your payment and you will receive an email.</div>
                                                        </>}
                                                    </>
                                                }
                                            </>} />
                                        }
                                    </>} />
                                </>
                                :
                                <>
                                    <p className="bg-error rounded text-center text-themeWhite font-semibold py-2">Please fill in all your filters before using this option</p>
                                </>
                            }
                        </>} />
                    </>
                }
            </>} />
        </Container>
    )
}