import styled from 'styled-components'

/* Props:
    itemWidth: Size (default "400px")
    gap: Size (default "16px")
*/
export const ResponsiveGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(min(100%,${props => props.itemWidth || "400px"}),1fr));
    gap: ${props => props.gap || "16px"};
`