import setNumber from "./setNumber";
import {winningNumbersStore} from "../../../../../stores/GlobalWinningNumbersStore";

export default async function computeCombinations(filters)
{
    const compute = async () => {
        let i1=0;
        let i2 = 0;
        let i3 = 0;
        let i4 = 0;
        let i5 = 0;
        let seq2 = 0;
        let seq3 = 0;
        let seq4 = 0;
        let seq5 = 0;
        let groups2 = 0;
        let groups3 = 0;
        let groups4 = 0;
        let groups5 = 0;
        let count = 0;
        let minNumber = filters.minNumber || 1
        let maxNum = filters.maxNumber || (filters.lottery === "Powerball" ? 69 : 70)
        let minSpread = filters.minSpread || 0
        let removeEvenlySpaced = filters.evenlySpaced === null ? false : !filters.evenlySpaced
        let maxSequence = filters.maxSequence || 5
        let maxSequenceGroups = filters.maxSequenceGroups !== null ? filters.maxSequenceGroups : 5
        let winningNumbers = {}
        let currentWinningNumberSearch1 = {}
        let currentWinningNumberSearch2 = {}
        let currentWinningNumberSearch3 = {}
        let currentWinningNumberSearch4 = {}
        
        if(filters.removeWinningNumbers)
        {
            if(filters.lottery === "Powerball")
            {
                winningNumbers = winningNumbersStore.state.powerballWinningNumbers
            }
            else
            {
                winningNumbers = winningNumbersStore.state.megaMillionsWinningNumbers
            }
        }

        for(i1 = minNumber; i1 <= maxNum-4; i1++)
        {
            currentWinningNumberSearch1 = winningNumbers[i1.toString()] || {}

            for(i2 = i1 + 1; i2 <= maxNum-3; i2++)
            {
                if(i2 - i1 == 1){seq2 = 2; if(seq2 > maxSequence){continue}; groups2=1; if(groups2 > maxSequenceGroups){continue;} }else{seq2 = 1; groups2 = 0;}
                currentWinningNumberSearch2 = currentWinningNumberSearch1[i2.toString()] || {}

                for(i3 = i2 + 1; i3 <= maxNum-2; i3++)
                {
                    if(i3 - i2 == 1){seq3 = seq2+1; if(seq3 > maxSequence){continue} if(seq3==2){groups3 = groups2+1; if(groups3 > maxSequenceGroups){continue}}else{groups3 = groups2} }else{seq3 = 1; groups3 = groups2;}
                    if(i3 - i2 == i2 - i1 && removeEvenlySpaced){continue}
                    currentWinningNumberSearch3 = currentWinningNumberSearch2[i3.toString()] || {}

                    for(i4 = i3 + 1; i4 <= maxNum-1; i4++)
                    {
                        if(i4 - i3 == 1){seq4 = seq3+1; if(seq4 > maxSequence){continue} if(seq4==2){groups4 = groups3+1; if(groups4 > maxSequenceGroups){continue}}else{groups4 = groups3} }else{seq4 = 1; groups4 = groups3;}
                        if(i4 - i3 == i3 - i2 && removeEvenlySpaced){continue}
                        currentWinningNumberSearch4 = currentWinningNumberSearch3[i4.toString()] || {}
                        
                        for(i5 = i4 + 1; i5 <= maxNum; i5++)
                        {
                            if(i5 - i4 == 1){seq5 = seq4+1; if(seq5 > maxSequence){continue} if(seq5==2){groups5 = groups4+1; if(groups5 > maxSequenceGroups){continue}}else{groups5 = groups4} }
                            if(i5 - i4 == i4 - i3 && removeEvenlySpaced){continue}

                            if(i5 - i1 < minSpread)
                            {
                                continue
                            }

                            if(currentWinningNumberSearch4[i5.toString()]){continue}
                            
                            count++
                        }
                    }
                }
            }
        }

        return count
    }

    this.IsComputing.set(true)
    let newCount = await compute()
    this.IsComputing.set(false)
    setNumber.bind(this)(newCount)
}