import { TicketSelectorForFile } from "../TicketSelectorForFile"
import { useLotteryFiltersStore } from "../store"
import { Container, Icon } from "./Style"

export const FileDownloadSection = () => {

    const {
        state: {
            SelectedFileOutputType,
            FilterSelections,
            PriceSettings,
            FileDownloadFilters
        },
        selectFileOutputType
    } = useLotteryFiltersStore()

    return (

        <>
            <div className="font-title text-4xl text-primary pl-2 max-w-[400px] w-[95%] mx-auto mt-12 mb-6 text-center">Get your tickets</div>
            <Container>
                <SelectedFileOutputType render={type => <>
                    <Icon selected={type === "TEXT"} onClick={() => selectFileOutputType("TEXT")}>
                        <p>Download your list of numbers to your phone or computer.</p>
                        <img src="/images/txt-file.svg" alt="text file download" />
                    </Icon>

                    <FileDownloadFilters render={filter => <>

                        <PriceSettings render={priceSettings => <>
                            {priceSettings.allowPrintingOnCard &&
                                <Icon selected={type === "PRINT"} onClick={() => selectFileOutputType("PRINT", filter.state ? filter.state : "FL")}>
                                    <img src="/images/printer.svg" alt="print on lottery cards" />
                                    <p>Print your lottery cards with automatically filled in ticket selections.</p>
                                </Icon>
                            }
                        </>} />

                    </>} />
                </>} />
            </Container>

            <TicketSelectorForFile />
        </>

    )
}