import api from "../../../../utils/api"

export default async function setEnablePayments(isPaymentEnabled)
{
    this.EnablePayments.set(isPaymentEnabled)
    try
    {
        await api.patch('/price-control/values', {
            enablePayments: isPaymentEnabled
        })
    }
    catch (err)
    {
        console.log(err)
        alert("Failed to change 'Enable Payments' value")
    }
}