import ReactGA from "react-ga4"

export * from "./TrackPage"

export const sendPageView = ()=> {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
  })
}

export const sendCustomPageView = (path) => {
  //window.location.protocol + "//" + window.location.host;
  ReactGA.send({
    hitType: "pageview",
    page: window.location.protocol + "//" + window.location.host+path
  })
}