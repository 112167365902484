import styled from "styled-components";

export const MenuIconContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    padding: 16px 32px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: ${props => props.theme.colors.white()};
    font-size: 32px;

    &:hover {
        color: ${props => props.theme.colors.primary()};
    }
`

export const MenuModalBG = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    background-color: #00060Fee;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fade-in-MenuModalBG 0.5s ease;
    
    @keyframes fade-in-MenuModalBG {
        from { background-color: #00060F00; }
        to { background-color: #00060Fee; }
    }
`

export const MenuContainer = styled.div`
    width: 400px;
    max-width: 95%;
    border: solid 1px ${props => props.theme.colors.primary()};
    border-radius: 3px;
    padding: 4px;
    opacity: 0;
    animation: fade-in-MenuContainer 0.5s ease 0.25s forwards;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 32px;

    @keyframes fade-in-MenuContainer {
        from { opacity: 0; }
        to { opacity: 1; }
    }

`

/**
 * props
 * - menuIndex
 */
export const MenuItem = styled.div`
    width: 100%;
    border: solid 1px ${props => props.theme.colors.primary()};
    border-radius: 3px;
    cursor: pointer;
    background-color: #4B000A33;
    opacity: 0;
    animation: fade-in-MenuItem 0.5s ease ${props => (props.menuIndex ? props.menuIndex*0.05 : 0) + 0.4}s;
    animation-fill-mode: both;


    @keyframes fade-in-MenuItem {
        0% {
            opacity: 0;
            background-color: #4B000A33;
            color: ${props => props.theme.colors.primary()};
        }
        20% {
            opacity: 0.2;
            background-color: ${props => props.theme.colors.primary()};
            color: ${props => props.theme.colors.white()};
        }
        100% {
            opacity: 1;
            background-color: #4B000A33;
            color: ${props => props.theme.colors.primary()};
        }
    }

    & > div {

        border-radius: 3px;
        padding: 8px;
        text-align: center;
        background-color: transparent;
        color: ${props => props.theme.colors.primary()};
        transition: all 0.2s ease;

        &:hover {
            background-color: ${props => props.theme.colors.primary()};
            color: ${props => props.theme.colors.white()};
        }
        
    }
`

/**
 * props
 * - menuIndex
 */
export const RedMenuItem = styled.div`
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    background-color: #4B000A;
    opacity: 0;
    animation: fade-in-RedMenuItem 0.5s ease ${props => (props.menuIndex ? props.menuIndex*0.05 : 0) + 0.4}s;
    animation-fill-mode: both;


    @keyframes fade-in-RedMenuItem {
        0% {
            opacity: 0;
            background-color: #4B000A33;
            color: ${props => props.theme.colors.primary()};
        }
        20% {
            opacity: 0.2;
            background-color: ${props => props.theme.colors.primary()};
            color: ${props => props.theme.colors.white()};
        }
        100% {
            opacity: 1;
            background-color: #4B000A;
            color: ${props => props.theme.colors.primary()};
        }
    }

    & > div {

        border-radius: 3px;
        padding: 8px;
        text-align: center;
        background-color: transparent;
        color: ${props => props.theme.colors.primary()};
        transition: all 0.2s ease;

        &:hover {
            background-color: ${props => props.theme.colors.bg(50,20)};
            color: ${props => props.theme.colors.white()};
        }
        
    }
`

export const MenuDivider = styled.hr`
    border-color: ${props => props.theme.colors.primary()};
`