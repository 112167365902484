import { Select } from "../../../components/base/elements/input/Select"
import { useValue } from "../../../components/base/hooks/useValue"
import { useLotteryFiltersStore } from "../store"
import { NumberSelector } from "./Style"

let stateMap = {
    "FL": "Florida",
    "OH": "Ohio",
    "MI": "Michigan",
    "GA": "Georgia",
    "TN": "Tennessee",
    "KY": "Kentucky",
    "IN": "Indiana",
    "IL": "Illinois",
    "IA": "Iowa",
}

stateMap = Object.fromEntries(
    Object.entries(stateMap).sort((a, b) => a[1].localeCompare(b[1]))
)
export const StateSelector = () => {

    const {
        state: {
            FileDownloadFilters,
        },
        setCountryState
    } = useLotteryFiltersStore()

    let selection = "FL"
    const EditMode = useValue(false)

    const applyValue = () => {
        setCountryState(selection)
        EditMode.set(false)
    }

    return (
        <EditMode render={editMode => <>
            {editMode === false &&
                <NumberSelector onClick={() => EditMode.set(true)}>
                    <p className="text-center text-xl pt-2">In the state of: <span className="text-primary font-bold text-2xl">
                        <FileDownloadFilters render={filter => filter.state ? stateMap[filter.state] : "Florida"} />
                    </span></p>
                </NumberSelector>
            }
            {editMode === true &&
                <NumberSelector selected={true}>
                    <p className="text-center text-xl pt-2">In the state of: <span className="text-primary font-bold text-2xl">
                        <Select defaultValue={FileDownloadFilters.get().state || "FL"} onChange={(e) => selection = e.target.value}>
                            {Object.entries(stateMap).map(([key, value]) =>
                                <option key={key} value={key}>{value}</option>)}

                        </Select>
                    </span></p>

                    <div className="flex flex-row-reverse px-3 gap-3 mt-3 relative">
                        <span className="text-base cursor-pointer text-secondary hover:underline " onClick={() => EditMode.set(false)}>Cancel</span>
                        <span className="text-base cursor-pointer text-secondary inline-block relative hover:underline" onClick={applyValue}>Save</span>
                    </div>
                </NumberSelector>
            }
        </>} />
    )
}