import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { MakeVipGuestsStore, VipGuestStoreContext } from './store'
import { StoreLoader } from '../../components/base/util/StoreLoader'
import { useValue } from '../../components/base/hooks/useValue'
import { AddGuestModal } from './AddGuestModal'
import { useValueRef } from '../../components/base/hooks/useValueRef'

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
const projects = [
    {
        id: 1,
        name: 'GraphQL API',
        href: '#',
        status: 'Complete',
        createdBy: 'Leslie Alexander',
        dueDate: 'March 17, 2023',
        dueDateTime: '2023-03-17T00:00Z',
    },
    {
        id: 2,
        name: 'New benefits plan',
        href: '#',
        status: 'In progress',
        createdBy: 'Leslie Alexander',
        dueDate: 'May 5, 2023',
        dueDateTime: '2023-05-05T00:00Z',
    },
    {
        id: 3,
        name: 'Onboarding emails',
        href: '#',
        status: 'In progress',
        createdBy: 'Courtney Henry',
        dueDate: 'May 25, 2023',
        dueDateTime: '2023-05-25T00:00Z',
    },
    {
        id: 4,
        name: 'iOS app',
        href: '#',
        status: 'In progress',
        createdBy: 'Leonard Krasner',
        dueDate: 'June 7, 2023',
        dueDateTime: '2023-06-07T00:00Z',
    },
    {
        id: 5,
        name: 'Marketing site redesign',
        href: '#',
        status: 'Archived',
        createdBy: 'Courtney Henry',
        dueDate: 'June 10, 2023',
        dueDateTime: '2023-06-10T00:00Z',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



export const VipGuests = () => {

    const store = MakeVipGuestsStore()

    const {
        state: {
            VipGuests
        },
        deleteGuest
    } = store

    const IsAddGuestModalOpen = useValue(false)

    return (
        <StoreLoader store={store} context={VipGuestStoreContext} Component={() => <>

            <AddGuestModal OpenState={IsAddGuestModalOpen} />

            <div>
                <ul role="list" className="divide-y divide-gray-100">
                    <VipGuests mapArray={guest => <>
                        <li key={guest._id} className="flex items-center justify-between gap-x-6 py-5">
                            <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                    <p className="text-lg font-semibold leading-6 text-gray-900">{guest.email}</p>
                                </div>
                                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                    <p className="whitespace-nowrap">
                                        Joined on <time dateTime={new Date(guest.registeredAt)}>{(new Date(guest.registeredAt)).toLocaleDateString()}</time>
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                                <Menu as="div" className="relative flex-none">
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                        <span className="sr-only">Open options</span>
                                        <i className="fa-solid fa-ellipsis-vertical h-5 w-5" aria-hidden="true"></i>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <span
                                                        className={classNames(
                                                            active ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                            'cursor-pointer'
                                                        )}
                                                        onClick={() => deleteGuest(guest._id)}
                                                    >
                                                        Delete
                                                    </span>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </li>
                    </>} />
                </ul>
                <div>
                    <span onClick={() => IsAddGuestModalOpen.set(true)} className='text-bg cursor-pointer text-xl hover:text-primary'>+ Add Guest</span>
                </div>
            </div>
        </>} />
    )
}