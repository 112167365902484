
import { saveAs } from 'file-saver'
import api from '../../../../utils/api'

export default async function downloadCalibrationFile()
{

    try
    {
        let {data} = await api.post('/lottery/download/calibration-file', {
            filterSelections: this.FilterSelections.get(),
            fileDownloadFilters: this.FileDownloadFilters.get()
        }, { responseType: 'blob' })

        saveAs(data, "printer-calibration.pdf")

    }
    catch(err)
    {
        console.log(err)
        alert(err.message)
    }

}