import styled from "styled-components"

export const PrimaryButton = styled.button`
    /* tailwind: rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 */

    /* rounded-md */
    border-radius: 0.375rem;

    /* bg-indigo-600 */
    background-color: ${props => props.theme.colors.primary()};

    /* px-3.5 */
    padding-left: 0.875rem;
    padding-right: 0.875rem;

    /* py-2.5 */
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    /* text-sm */
    font-size: 0.875rem;

    /* font-semibold */
    font-weight: 600;

    /* text-white */
    color: ${props => props.theme.colors.white()};

    /* shadow-sm */
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
        background-color: ${props => props.theme.colors.primary(10,10)};
    }

    &:focus-visible {
        /* outline */
        outline-style: solid;

        /* outline-2 */
        outline-width: 2px;

        /* outline-offset-2 */
        outline-offset: 2px;

        outline-color: ${props => props.theme.colors.primary(5,5)};
    }
`