import styled from 'styled-components';

export const DigitContainer = styled.div`
    display: inline-block;
    width: 48px;
    height: 56px;
    background-color: ${props => props.theme.colors.white()};
    overflow: hidden;
    border-radius: 5px;
    box-shadow: inset 2px 2px 2px 0px rgba(0,0,0,0.5);
    border-top: 1px solid #aaa;
    border-left: 1px solid #aaa;
`

export const Digits = styled.div`
    display: flex;
    flex-direction: column;
`

export const Digit = styled.div`
    display: flex;
    width: 100%;
    height: 56px;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: ${props => props.theme.colors.black()};
    font-weight: 600;
`

export const NumberContainer = styled.div`
    /*flex gap-2 px-2 w-full sm:w-fit*/
    display: flex;
    gap: 8px;
    padding: 0px 8px;
    width: fit-content;

    @media screen and (max-width: 460px) {
        width: 100%;
    }
`

export const NumberSectionColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;

    @media screen and (max-width: 460px) {
        width: 100%;
    }
`