import display100Numbers from "./display100Numbers"

export default function selectPowerBalls(selection) {
    let newFilters = { ...this.FilterSelections.get(), selectedPowerBalls: selection }
    this.FilterSelections.set(newFilters)
    this.MaxActiveFilter.set(Math.max(this.ActiveFilterIndex.get() + 1, this.MaxActiveFilter.get()))
    this.ActiveFilterIndex.set(0)
    display100Numbers.bind(this)(newFilters)
    let newDownloadFilter = { ...this.FileDownloadFilters.get() }
    newDownloadFilter.printEvery = Math.floor(this.NumberCombinationStore.state.currentNumber / 100)
    this.FileDownloadFilters.set(newDownloadFilter)

    console.log("File download filters", this.FileDownloadFilters.get())
}