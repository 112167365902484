import { useEffect } from "react"
import { TemplateContainer } from "./Style"

export const TemplateBuilder = () => {

    let blackSquare = null
    let templateContainer = null
    let myPoints = null
    let count = 1

    const onMove = (e) => {
        blackSquare.style.left = (e.clientX + templateContainer.scrollLeft) + "px"
        blackSquare.style.top = (e.clientY + templateContainer.scrollTop) + "px"
    }

    const onPlaceDot = (e) => {
        let x = e.clientX + templateContainer.scrollLeft
        let y = e.clientY + templateContainer.scrollTop
        let dot = document.createElement("div")
        dot.innerText = `[${(x / 8).toFixed(2)}, ${(y / 8).toFixed(2)}],`
        myPoints.appendChild(dot)
        count++
        blackSquare.innerText = count
        console.log("current dot:", count)
    }

    const onRemoveLastDot = () => {
        myPoints.removeChild(myPoints.lastChild)
        count--
        blackSquare.innerText = count
        console.log("current dot:", count)
    }

    useEffect(() => {
        blackSquare = document.getElementById("blackSquare")
        templateContainer = document.getElementById("templateContainer")
        myPoints = document.getElementById("myPoints")
    }, [])

    return (
        <div>
            <TemplateContainer id="templateContainer">
                <div className="absolute inline-block top-0 left-0 w-[4896px] h-[6336px]" >
                    <img src="/images/templates/MEGAMILLIONS_FL2.png" alt="template" className="top-0 left-0 w-[4896px] h-[6336px]" onMouseMove={onMove} onClick={onPlaceDot} />
                </div>
                <div className="absolute flex justify-center items-center w-[73px] h-[73px] bg-black/75 -translate-x-1/2 -translate-y-1/2 pointer-events-none text-white text-4xl" id="blackSquare">
                    1
                </div>
            </TemplateContainer>
            <div className="inline-block align-top" >
                <div id="myPoints"></div>
                <button className="mt-8" onClick={onRemoveLastDot}>Remove Last</button>

            </div>
        </div>
    )
}