export default function selectPowerBall()
{
    this.NumberCombinationStore.setNumber(11238513)
    this.ActiveFilterIndex.set(0)
    this.FilterSelections.set({
        lottery: "Powerball",
        minSpread: null,
        minNumber: null,
        maxNumber: null,
        evenlySpaced: null,
        maxSequence: null,
        maxSequenceGroups: null,
        selectedPowerBalls: [],
        removeWinningNumbers: null
    })
    this.MaxActiveFilter.set(2)
}