import styled from "styled-components";

export const Container = styled.div`
    width: 700px;
    max-width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 16px;
`

/**
 * props: {selected: boolean}
 */
export const Icon = styled.div`
    width: 300px;
    height: 128px;
    background-color: ${props => props.selected ? props.theme.colors.primary() : props.theme.colors.bg(-50,10)};
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${props => props.selected ? 1.0 : 0.6};
    padding-left: 16px;
    padding-right: 16px;

    & > p {
        color: ${props => props.selected ? props.theme.colors.white() : props.theme.colors.cardText()};
        padding: 16px;
    }

    &:hover {
        opacity: ${props => props.selected ? 1.0 : 1.0};
        cursor: ${props => props.selected ? 'default' : 'pointer'};
    }

    & > img {
        height: 80%;
    }
`