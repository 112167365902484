import loadScriptFromURL from "./functions/loadScriptFromURL.js"

const makeScriptLoader = () => {
    const state = {
        loadedScripts: {}, //key: url, value: true
    }

    return {
        state,
        loadScriptFromURL: loadScriptFromURL.bind(state)
    }
}

export const scriptLoader = makeScriptLoader()