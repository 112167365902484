import styled from "styled-components";

export const TemplateContainer = styled.div`
    width: 1224px;
    height: 100vh;
    overflow: scroll;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
`