import { useValue } from "../../../../components/base/hooks/useValue"
import { useLotteryFiltersStore } from "../../store"
import { ApplyFilterButton, Tooltip } from "../Style"

export const MaxNumberFilterBody = () => {

    const {
        state: {
            FilterSelections
        },
        applyMaximumNumberFilter
    } = useLotteryFiltersStore()

    let selection = ""
    let ErrorMessage = useValue(null)

    const onValueChange = (e) => {

        selection = e.target.value
        if(selection == "")
        {
            ErrorMessage.set("This filter cannot be empty. You can press the skip button if you don't want to use it.")
            return
        }

        let number = parseInt(selection)
        if(isNaN(number)) {
            ErrorMessage.set("This filter must be a number.")
            return
        }

        let maxNumber = FilterSelections.get().lottery === "Powerball" ? 69 : 70
        if(number < 5) {
            ErrorMessage.set("Highest number cannot be less than 5")
            return
        }
        else if(number > maxNumber) {
            ErrorMessage.set("Highest number cannot be greater than "+maxNumber+" for the "+FilterSelections.get().lottery+" lottery.")
            return
        }

        ErrorMessage.set(null)
    }

    const applyClick = () => {
        onValueChange({target: {value: selection}})
        if(ErrorMessage.get() !== null) return
        applyMaximumNumberFilter(selection)
    }

    return (
        <div className="mt-2 flex align-middle justify-center gap-4 relative">
            <input 
                onChange={onValueChange}
                className="w-24 px-2 py-1 borde rounded-md focus:outline-none focus:border-indigo-500" type="number" id="name" name="name" 
                placeholder={FilterSelections.get().lottery === "Powerball" ? '5 - 69' : '5 - 70'}
            />
            <ErrorMessage render={message => <>
                {message === null &&
                    <ApplyFilterButton onClick={applyClick}>
                        Apply
                    </ApplyFilterButton>
                }
                {message !== null &&
                    <ApplyFilterButton error={true}>
                        Apply
                        <Tooltip top="50px">
                            {message}
                        </Tooltip>
                    </ApplyFilterButton>
                }
            </>} />
        </div>
    )
}