import { useNavigate } from "react-router-dom"
import { MenuContainer, MenuDivider, MenuIconContainer, MenuItem, MenuModalBG, RedMenuItem } from "./Style"
import { MakeHeaderStore } from "./store"
import { useRef } from "react"
import { useSelector } from "react-redux"
import { setAuthToken } from "../../../utils/api"
import authReducer from "../../reducers/auth"

export const Header = () => {

    const store = useRef(MakeHeaderStore())
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)

    const {
        state: {
            IsMenuOpen
        }
    } = store.current

    const onHomeClick = () => {
        navigate('/')
        IsMenuOpen.set(false)
    }

    const onLoginClick = () => {
        navigate('/login')
        IsMenuOpen.set(false)
    }

    const onLogoutClick = () => {
        authReducer.logout()
        setAuthToken(null)
        navigate('/login')
        IsMenuOpen.set(false)
    }

    const onDashboardClick = () => {
        navigate('/dashboard')
        IsMenuOpen.set(false)
    }

    let menuIndex = -1;

    const getMenuIndex = () => {
        menuIndex++
        return menuIndex
    }

    const finalMenuIndex = () => {
        let temp = menuIndex
        menuIndex = -1
        return temp
    }

    return (
        <>
            <IsMenuOpen conditionalRender={isMenuOpen => <>
                <MenuModalBG>
                    <MenuContainer>
                        <MenuItem menuIndex={getMenuIndex()}><div onClick={onHomeClick}>Home</div></MenuItem>
                        {/*<MenuItem menuIndex={getMenuIndex()}><div>Help</div></MenuItem>
                        <MenuItem menuIndex={getMenuIndex()}><div>Contact-Us</div></MenuItem>*/}
                        {auth.isAuthenticated &&
                            <MenuItem menuIndex={getMenuIndex()}><div onClick={onLogoutClick}>Sign Out</div></MenuItem>
                        }
                        {!auth.isAuthenticated &&
                            <MenuItem menuIndex={getMenuIndex()}><div onClick={onLoginClick}>Sign In</div></MenuItem>
                        }
                        {auth.isAuthenticated && auth.accountType === "SUPER_ADMIN" &&
                            <MenuItem menuIndex={getMenuIndex()}><div onClick={onDashboardClick}>Dashboard</div></MenuItem>
                        }
                        <MenuDivider />
                        <RedMenuItem menuIndex={finalMenuIndex()}><div onClick={() => IsMenuOpen.set(false)}>Close</div></RedMenuItem>
                    </MenuContainer>
                </MenuModalBG>
            </>} />

            <MenuIconContainer onClick={() => IsMenuOpen.set(true)} id="headerMenuContainer">
                {auth.isAuthenticated && 
                    <i className="fa-solid fa-circle-user"></i>
                }
                {!auth.isAuthenticated &&
                    <i className="fa-solid fa-bars"></i>
                }
            </MenuIconContainer>
        </>
    )
}