import styled from "styled-components";

export const FooterContainer = styled.div`
    background-color: ${props => props.theme.colors.bg()};
    text-align: center;
    color: ${props => props.theme.colors.white()};
    padding: 8px;
    font-size: 20px;
    position: sticky;
    top: 100%;
`