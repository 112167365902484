export default function display100Numbers(filters)
{
    let numCombinations = this.NumberCombinationStore.state.currentNumber
    let gap = Math.floor(numCombinations / 100)
    console.log("numCombinations",numCombinations)
    console.log("gap",gap)
    let numberIndex = 0;
    let numbers = []
    let powerBalls = filters.selectedPowerBalls

    let i1=0;
    let i2 = 0;
    let i3 = 0;
    let i4 = 0;
    let i5 = 0;
    let seq2 = 0;
    let seq3 = 0;
    let seq4 = 0;
    let seq5 = 0;
    let groups2 = 0;
    let groups3 = 0;
    let groups4 = 0;
    let groups5 = 0;
    let minNumber = filters.minNumber || 1
    let maxNum = filters.maxNumber || (filters.lottery === "Powerball" ? 69 : 70)
    let minSpread = filters.minSpread || 0
    let removeEvenlySpaced = filters.evenlySpaced === null ? false : !filters.evenlySpaced
    let maxSequence = filters.maxSequence || 5
    let maxSequenceGroups = filters.maxSequenceGroups !== null ? filters.maxSequenceGroups : 5

    //console.log("filters", filters)

    for(i1 = minNumber; i1 <= maxNum-4; i1++)
    {
        for(i2 = i1 + 1; i2 <= maxNum-3; i2++)
        {
            if(i2 - i1 == 1){seq2 = 2; if(seq2 > maxSequence){continue}; groups2=1; if(groups2 > maxSequenceGroups){continue;} }else{seq2 = 1; groups2 = 0;}

            for(i3 = i2 + 1; i3 <= maxNum-2; i3++)
            {
                if(i3 - i2 == 1){seq3 = seq2+1; if(seq3 > maxSequence){continue} if(seq3==2){groups3 = groups2+1; if(groups3 > maxSequenceGroups){continue}}else{groups3 = groups2} }else{seq3 = 1; groups3 = groups2;}
                if(i3 - i2 == i2 - i1 && removeEvenlySpaced){continue}

                for(i4 = i3 + 1; i4 <= maxNum-1; i4++)
                {
                    if(i4 - i3 == 1){seq4 = seq3+1; if(seq4 > maxSequence){continue} if(seq4==2){groups4 = groups3+1; if(groups4 > maxSequenceGroups){continue}}else{groups4 = groups3} }else{seq4 = 1; groups4 = groups3;}
                    if(i4 - i3 == i3 - i2 && removeEvenlySpaced){continue}
                    
                    for(i5 = i4 + 1; i5 <= maxNum; i5++)
                    {
                        if(i5 - i4 == 1){seq5 = seq4+1; if(seq5 > maxSequence){continue} if(seq5==2){groups5 = groups4+1; if(groups5 > maxSequenceGroups){continue}}else{groups5 = groups4} }
                        if(i5 - i4 == i4 - i3 && removeEvenlySpaced){continue}

                        if(i5 - i1 < minSpread)
                        {
                            continue
                        }
                        
                        numberIndex++
                        if(numberIndex >= gap)
                        {
                            numberIndex = 0
                            numbers.push(`${i1.toString().padStart(2, ' ')} ${i2.toString().padStart(2, ' ')} ${i3.toString().padStart(2, ' ')} ${i4.toString().padStart(2, ' ')} ${i5.toString().padStart(2, ' ')} | ${powerBalls[Math.floor(Math.random() * powerBalls.length)].toString().padStart(2, ' ')}`)
                        }
                    }
                }
            }
        }
    }

    this.DemoNumberList.set(numbers)
}