import api from "../../../../utils/api"

export default async function setAllowPrintingOnCard(isAllowed)
{
    this.AllowPrintingOnCard.set(isAllowed)
    try
    {
        await api.patch('/price-control/values', {
            allowPrintingOnCard: isAllowed
        })
    }
    catch (err)
    {
        console.log(err)
        alert("Failed to change 'Allow Printing on Card' value")
    }
}