import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App/index.js';
import ReactGA from 'react-ga4';
import { ThemeProvider } from 'styled-components'
import { themeSettings } from './App/theme/theme-settings';
import store from './App/store.js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


if(process.env.REACT_APP_DEV_MODE === '0'){
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE?.toString() || '')
}


root.render(
  <Provider store={store}>
    <ThemeProvider theme={themeSettings}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
);
