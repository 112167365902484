import { useLotteryFiltersStore } from "../../store"
import { FilterBodySmallButton } from "../Style"

export const RemoveWinningNumbersFilterBody = () => {
    const {
        applyRemovePreviousWinningNumbersFilter
    } = useLotteryFiltersStore()

    return (
        <div className="mt-2 flex align-middle justify-center gap-4">
            <FilterBodySmallButton onClick={() => applyRemovePreviousWinningNumbersFilter(false)}>Keep</FilterBodySmallButton>
            <FilterBodySmallButton onClick={() => applyRemovePreviousWinningNumbersFilter(true)}>Remove</FilterBodySmallButton>
        </div>
    )
}