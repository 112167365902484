import styled from 'styled-components';

const Container = styled.div`
    text-align: left;
    background-color: ${props => props.theme.colors.bg(15, 15)};
    color: ${props => props.theme.colors.white()};
    font-weight: 400;
    font-size: 18px;
    padding: 16px 24px;
    margin: 24px 0px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const ForBetterResultsMessage = () => {
    return (
        <Container>
            <i className="fa-solid fa-circle-exclamation text-4xl"></i>
            <div>For more accurate results while printing, please print one card/page at a time.</div>
        </Container>
    )
}