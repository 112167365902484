/**
 * @typedef {'TEXT' | 'PRINT'} FileType
 */

/**
 * 
 * @param {FileType} fileType - TEXT for the txt file and PRINT for the pdf file
 */
export default function selectFileOutputType(fileType, state) {
    this.SelectedFileOutputType.set(fileType)
    if (fileType === "TEXT") {
        this.FileDownloadFilters.set({
            printEvery: Math.floor(this.NumberCombinationStore.state.currentNumber / 100),
            numberOfTickets: 100,
            special1: false,
            special2: false
        })
    }
    else if (fileType === "PRINT") {
        this.FileDownloadFilters.set({
            printEvery: Math.floor(this.NumberCombinationStore.state.currentNumber / 100),
            state: state,
            numberOfTickets: 100,
            numberOfCards: 20,
            special1: false,
            special2: false
        })
    }

    this.IsPaypalPromptOpen.set(false)
}