import { PrimaryButton } from "../../components/base/elements/input/buttons/PrimaryButton"
import { StoreLoader } from "../../components/base/util/StoreLoader"
import { MakePriceControlStore } from "./store"

export const PriceControl = () => {

    const store = MakePriceControlStore()

    const {
        state: {
            EnablePayments,
            AllowPrintingOnCard,
            TextFilePrice,
            PdfFilePrice,
            VipAccountPrice
        },
        setEnablePayments,
        setTextFilePrice,
        setPdfFilePrice,
        setVipAccountPrice,
        setAllowPrintingOnCard
    } = store

    return (
        <div>
            <h1 className="text-3xl mb-16">Price Control</h1>
            <StoreLoader store={store} Component={() => <>
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <label className="flex flex-col mb-8">
                            <span className="text-xl">Enable Payments</span>
                            <EnablePayments render={enablePayments => <div>
                                {enablePayments === true && 
                                    <>
                                    <span className="text-primary font-bold">YES</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className="text-slate-500 hover:underline cursor-pointer" onClick={() => setEnablePayments(false)}>no</span>
                                    </>
                                }
                                {enablePayments === false && 
                                    <>
                                    <span className="text-slate-500 hover:underline cursor-pointer" onClick={() => setEnablePayments(true)}>yes</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className="text-primary font-bold">NO</span>
                                    </>
                                }
                            </div>} />
                            
                        </label>
                    </div>

                    <div className="flex flex-row">
                        <label className="flex flex-col gap-2 mb-8">
                            <span className="text-xl">Text File Price</span>
                            <input type="text" onChange={(e) => TextFilePrice.set(e.target.value)} defaultValue={TextFilePrice.get()} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            <PrimaryButton onClick={setTextFilePrice}>Save</PrimaryButton>
                        </label>
                    </div>

                    <div className="flex flex-row">
                        <label className="flex flex-col gap-2 mb-8">
                            <span className="text-xl">PDF File Price</span>
                            <input type="text" onChange={(e) => PdfFilePrice.set(e.target.value)} defaultValue={PdfFilePrice.get()} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            <PrimaryButton onClick={setPdfFilePrice}>Save</PrimaryButton>
                        </label>
                    </div>

                    <div className="flex flex-row">
                        <label className="flex flex-col gap-2 mb-8">
                            <span className="text-xl">VIP Account Price</span>
                            <input type="text" onChange={(e) => VipAccountPrice.set(e.target.value)} defaultValue={VipAccountPrice.get()} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            <PrimaryButton onClick={setVipAccountPrice}>Save</PrimaryButton>
                        </label>
                    </div>

                    <div className="flex flex-row">
                        <label className="flex flex-col mb-8">
                            <span className="text-xl">Allow Printing on Card</span>
                            <AllowPrintingOnCard render={allowPrintingOnCard => <div>
                                {allowPrintingOnCard === true && 
                                    <>
                                    <span className="text-primary font-bold">YES</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className="text-slate-500 hover:underline cursor-pointer" onClick={() => setAllowPrintingOnCard(false)}>no</span>
                                    </>
                                }
                                {allowPrintingOnCard === false && 
                                    <>
                                    <span className="text-slate-500 hover:underline cursor-pointer" onClick={() => setAllowPrintingOnCard(true)}>yes</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className="text-primary font-bold">NO</span>
                                    </>
                                }
                            </div>} />
                            
                        </label>
                    </div>
                </div>
            </>} />
        </div>
    )
}