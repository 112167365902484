import { useValue } from "../../../components/base/hooks/useValue"
import load from "./functions/load"
import setAllowPrintingOnCard from "./functions/setAllowPrintingOnCard"
import setEnablePayments from "./functions/setEnablePayments"
import setPdfFilePrice from "./functions/setPdfFilePrice"
import setTextFilePrice from "./functions/setTextFilePrice"
import setVipAccountPrice from "./functions/setVipAccountPrice"

export const MakePriceControlStore = () => {
    const state = {
        EnablePayments: useValue(true),
        AllowPrintingOnCard: useValue(true),
        TextFilePrice: useValue("5.00"),
        PdfFilePrice: useValue("10.00"),
        VipAccountPrice: useValue("15.00")
    }

    return {
        state,
        load: load.bind(state),
        setEnablePayments: setEnablePayments.bind(state),
        setTextFilePrice: setTextFilePrice.bind(state),
        setPdfFilePrice: setPdfFilePrice.bind(state),
        setVipAccountPrice: setVipAccountPrice.bind(state),
        setAllowPrintingOnCard:setAllowPrintingOnCard.bind(state)
    }
}