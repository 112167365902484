export default async function loadScriptFromURL(url)
{
    if(this.loadedScripts[url])
    {
        return
    }

    await new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.onload = () => {
            resolve()
        }
        script.onerror = () => {
            throw new Error('Failed to load script. Please contact customer support.')
        }
        document.body.appendChild(script)
    })

    this.loadedScripts[url] = true

    return
}