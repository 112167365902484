
import { useValue } from "../../../components/base/hooks/useValue"
import { Tooltip } from "../FilterButtons/Style"
import { useLotteryFiltersStore } from "../store"
import { NumberSelector } from "./Style"

export const NumberOfCardsSelector = () => {
    const {
        state: {
            FileDownloadFilters,
            NumberCombinationStore,
            FilterSelections
        },
        setNumberOfCards
    } = useLotteryFiltersStore()

    const EditMode = useValue(false)

    let selection = ""
    let ErrorMessage = useValue(null)

    const onValueChange = (e) => {

        selection = e.target.value
        if (selection == "") {
            ErrorMessage.set("Number of cards cannot be empty.")
            return
        }

        let number = parseInt(selection)
        if (isNaN(number)) {
            ErrorMessage.set("Number of cards must be a number.")
            return
        }

        if (number < 5) {
            ErrorMessage.set("Number of cards cannot be smaller than 5")
            return
        }
        if (number > 2000) {
            ErrorMessage.set("Number of cards cannot be higher than 2000")
            return
        }
        else if (number > Math.floor(NumberCombinationStore.state.currentNumber / 5)) {
            ErrorMessage.set("You don't have enough combinations to generate that many cards.")
            return
        }

        ErrorMessage.set(null)
    }

    const applyValue = () => {
        onValueChange({ target: { value: selection } })
        if (ErrorMessage.get() !== null) return
        setNumberOfCards(selection)
        EditMode.set(false)
    }

    return (

        <EditMode render={editMode => <>
            {editMode === false &&
                <NumberSelector onClick={() => EditMode.set(true)}>
                    <p className="text-center text-xl pt-2">Number of cards: <span className="text-primary font-bold text-2xl">
                        <FileDownloadFilters render={filter => filter.numberOfCards || 20} />
                    </span></p>
                </NumberSelector>
            }
            {editMode === true &&
                <NumberSelector selected={true}>
                    <p className="text-center text-xl pt-2">Number of cards: <span className="text-primary font-bold text-2xl">
                        <input type="number" className="w-28 mx-1 px-2" onChange={onValueChange} placeholder="5+" />
                    </span></p>
                    <div className="flex flex-row-reverse px-3 gap-3 mt-3 relative">
                        <span className="text-base cursor-pointer text-secondary hover:underline " onClick={() => EditMode.set(false)}>Cancel</span>

                        <ErrorMessage render={message => <>
                            {message === null &&
                                <span className="text-base cursor-pointer text-secondary inline-block relative hover:underline" onClick={applyValue}>Save</span>
                            }
                            {message !== null &&
                                <span className="text-base cursor-pointer text-secondary opacity-75 inline-block group">
                                    Save
                                    <Tooltip className="opacity-0 group-hover:opacity-100" top="28px" indicatorLeft="78%">{message}</Tooltip>
                                </span>
                            }
                        </>} />

                    </div>
                </NumberSelector>
            }
        </>} />

    )
}