export default function selectMegamillions()
{
    this.NumberCombinationStore.setNumber(12103014)
    this.ActiveFilterIndex.set(0)
    this.FilterSelections.set({
        lottery: "Mega Millions",
        minSpread: null,
        minNumber: null,
        maxNumber: null,
        evenlySpaced: null,
        maxSequence: null,
        maxSequenceGroups: null,
        selectedPowerBalls: [],
        removeWinningNumbers: null
    })
    this.MaxActiveFilter.set(2)
}