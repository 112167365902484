import { ResponsiveGrid } from "../../../components/base/layout/ResponsiveGrid"
import { useLotteryFiltersStore } from "../store"
import { ApplyFilterButton, FilterCard, LotteryImage } from "./Style"
import { EvenlySpacedFilterBody } from "./filterBodies/EvenlySpacedFilterBody"
import { MaxNumberFilterBody } from "./filterBodies/MaxNumberFilterBody"
import { MaxSequenceFilterBody } from "./filterBodies/MaxSequenceFilterBody"
import { MaxSequenceGroupsFilterBody } from "./filterBodies/MaxSequenceGroupsFilterBody"
import { MinNumberFilterBody } from "./filterBodies/MinNumberFilterBody"
import { MinSpreadFilterBody } from "./filterBodies/MinSpreadFilterBody"
import { PowerBallFilterBody } from "./filterBodies/PowerBallFilterBody"
import { RemoveWinningNumbersFilterBody } from "./filterBodies/RemoveWinningNumbersFilterBody"

export const FilterButtons = ({filtersRef}) => {

    const {
        state: {
            MaxActiveFilter,
            ActiveFilterIndex,
            FilterSelections
        },
        selectFilter,
        selectPowerBall,
        selectMegamillions,
        applyMinimumNumberFilter,
        applyMaximumNumberFilter,
        applyMininumSpreadFilter,
        applyEvenlySpacedNumbersFilter,
        applyMaxSequenceFilter,
        applyMaxSequenceGroupsFilter,
        applyRemovePreviousWinningNumbersFilter
    } = useLotteryFiltersStore()

    return (
        <div className="mt-2 p-2 block min-h-64">
            <MaxActiveFilter render={maxActiveFilter =>
                <ActiveFilterIndex render={activeFilterIndex =>
                    <div className="flex flex-wrap gap-4 justify-center">

                        <FilterSelections render={filterSelections => <>

                            <FilterCard ref={filtersRef} active={maxActiveFilter >= 1} pulsate={maxActiveFilter == 1} selected={activeFilterIndex === 1} onClick={() => selectFilter(1)}>
                                {activeFilterIndex === 1 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="text-center">Which lottery would you like to play?</p>
                                        <div className="flex gap-4 justify-center pt-1">
                                            <LotteryImage src="/images/powerballIcon.jpg" className="cursor-pointer hover:shadow-lg" onClick={selectPowerBall}/>
                                            <LotteryImage src="/images/megaMillionsIcon.jpg" className="cursor-pointer hover:shadow-lg" onClick={selectMegamillions}/>
                                        </div>
                                        <div className="flex -mt-1 pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }

                                {activeFilterIndex !== 1 &&
                                    <>
                                        <p className="pl-2">Lottery</p>
                                        <div className="text-center">{filterSelections.lottery}</div>
                                    </>
                                }
                            </FilterCard>


                            <FilterCard active={maxActiveFilter >= 2} pulsate={maxActiveFilter == 2} selected={activeFilterIndex === 2} onClick={() => selectFilter(2)}>
                                {activeFilterIndex === 2 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 pr-2">Lowest possible number in your squence of white balls</p>
                                        <MinNumberFilterBody />
                                        <div className="flex justify-between pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => applyMinimumNumberFilter(1)}>Skip</span>
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 2 &&
                                    <>
                                        <p className="pl-2">Lowest Number</p>
                                        <div className="text-center">{filterSelections.minNumber}</div>
                                    </>
                                }
                            </FilterCard>


                            <FilterCard active={maxActiveFilter >= 3} pulsate={maxActiveFilter == 3} selected={activeFilterIndex === 3} onClick={() => selectFilter(3)}>
                                {activeFilterIndex === 3 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 pr-2">Highest possible number in your squence of white balls</p>
                                        <MaxNumberFilterBody />
                                        <div className="flex justify-between pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => applyMaximumNumberFilter((filterSelections.lottery === "Powerball" ? 69 : 70))}>Skip</span>
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 3 &&
                                    <>
                                        <p className="pl-2">Highest Number</p>
                                        <div className="text-center">{filterSelections.maxNumber}</div>
                                    </>
                                }
                            </FilterCard>


                            <FilterCard active={maxActiveFilter >= 4} pulsate={maxActiveFilter == 4} selected={activeFilterIndex === 4} onClick={() => selectFilter(4)}>
                                {activeFilterIndex === 4 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 ">Minimumn spread between the smallest number and the largest number:</p>
                                        <MinSpreadFilterBody />
                                        <div className="flex justify-between pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => applyMininumSpreadFilter(0)}>Skip</span>
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 4 &&
                                    <>
                                        <p className="pl-2">Min Spread</p>
                                        <div className="text-center">{filterSelections.minSpread}</div>
                                    </>
                                }
                            </FilterCard>


                            <FilterCard active={maxActiveFilter >= 5} pulsate={maxActiveFilter == 5} selected={activeFilterIndex === 5} onClick={() => selectFilter(5)}>
                                {activeFilterIndex === 5 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 ">This will eliminate evenly spaced numbers. (example: 2-4-6-8-10)</p>
                                        <EvenlySpacedFilterBody />
                                        <div className="flex justify-between pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => applyEvenlySpacedNumbersFilter(true)}>Skip</span>
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 5 &&
                                    <>
                                        <p className="pl-2">Evenly Spaced #</p>
                                        <div className="text-center">{filterSelections.evenlySpaced !== null ? (filterSelections.evenlySpaced ? 'Allow' : 'Remove') : ''}</div>
                                    </>
                                }
                            </FilterCard>


                            <FilterCard active={maxActiveFilter >= 6} pulsate={maxActiveFilter == 6} selected={activeFilterIndex === 6} onClick={() => selectFilter(6)}>
                                {activeFilterIndex === 6 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 ">Maximum sequence of consecutive numbers</p>
                                        <MaxSequenceFilterBody />
                                        <div className="flex justify-between pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => applyMaxSequenceFilter(0)}>Skip</span>
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 6 &&
                                    <>
                                        <p className="pl-2">Max Sequence</p>
                                        <div className="text-center">{filterSelections.maxSequence}</div>
                                    </>
                                }
                            </FilterCard>


                            <FilterCard active={maxActiveFilter >= 7} pulsate={maxActiveFilter == 7} selected={activeFilterIndex === 7} onClick={() => selectFilter(7)}>
                                {activeFilterIndex === 7 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 ">Maximum groups of 2 or more consecutive numbers</p>
                                        <MaxSequenceGroupsFilterBody />
                                        <div className="flex justify-between pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => applyMaxSequenceGroupsFilter(0)}>Skip</span>
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 7 &&
                                    <>
                                        <p className="pl-2">Max Seq. Groups</p>
                                        <div className="text-center">{filterSelections.maxSequenceGroups}</div>
                                    </>
                                }
                            </FilterCard>  


                            <FilterCard active={maxActiveFilter >= 8} pulsate={maxActiveFilter == 8} selected={activeFilterIndex === 8} onClick={() => selectFilter(8)}>
                                {activeFilterIndex === 8 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 ">Would you like to remove previously winning numbers?</p>
                                        <RemoveWinningNumbersFilterBody />
                                        <div className="flex justify-between pr-2 pl-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={() => applyRemovePreviousWinningNumbersFilter(false)}>Skip</span>
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 8 &&
                                    <>
                                        <p className="pl-2">Remove Winning #</p>
                                        <div className="text-center">{filterSelections.removeWinningNumbers !== null ? (filterSelections.removeWinningNumbers ? 'Remove' : 'Keep') : ''}</div>
                                    </>
                                }
                            </FilterCard>      


                            <FilterCard active={maxActiveFilter >= 9} pulsate={maxActiveFilter == 9} selected={activeFilterIndex === 9} onClick={() => selectFilter(9)}>
                                {activeFilterIndex === 9 && 
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <p className="pl-2 ">Select all {filterSelections.lottery === "Powerball" ? 'Powerball(s)' : "Mega Ball(s)"} you desire (randomly selected for each combination)</p>
                                        <PowerBallFilterBody />
                                        <div className="-mt-1 text-right pr-2">
                                            <span className="text-base cursor-pointer hover:underline" onClick={(e) => selectFilter(0)}>Close</span>
                                        </div>
                                    </div>
                                }
                                {activeFilterIndex !== 9 &&
                                    <>
                                        <p className="pl-2">{filterSelections.lottery === null ? 'Power/Mega ball' : (filterSelections.lottery === "Powerball" ? 'Powerball(s)' : "Mega Ball(s)")}</p>
                                        <div className="text-center">{filterSelections.selectedPowerBalls.length > 0 ? filterSelections.selectedPowerBalls.length + " Selected" : ''}</div>
                                    </>
                                }
                            </FilterCard>

                        </>}/>
                    </div>
                }/>
            }/>

        </div>
    )
}