import api from "../../../../utils/api"

export default async function load()
{
    const {data} = await api.get('/price-control/values')
    this.EnablePayments.set(data.enablePayments)
    this.TextFilePrice.set(data.txtFilePrice)
    this.PdfFilePrice.set(data.pdfFilePrice)
    this.VipAccountPrice.set(data.vipAccountPrice)
    this.AllowPrintingOnCard.set(data.allowPrintingOnCard)
}
