
import { combineReducers, createStore, applyMiddleware } from 'redux'
//import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import authReducer from './reducers/auth'

const reducers = combineReducers({
  auth: authReducer.redux.bind(authReducer),
})

let middleware = [thunk]
const store = createStore(reducers, {}, applyMiddleware(...middleware))

authReducer.setDispatch(store.dispatch)

export default store
