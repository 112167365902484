import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Loader } from '../../components/base/elements/Loader'
import authReducer, { useAuthReducer } from '../../App/reducers/auth'

export const PrivateRoute = ({ children, accountType = null }) => {
    const auth = useAuthReducer()

  if (auth.loaded) {
    if (auth.isAuthenticated) {
      if (accountType === null || auth.accountType === accountType || (Array.isArray(accountType) && accountType.includes(auth.accountType))) {
        return <>{children}</>
      } else {
        return <Navigate to='/login' />
      }
    }

    return <Navigate to='/login' />
  }

  return (
    <div className='text-center mt-16'>
      <Loader size="48px"/>
    </div>
  )
}
