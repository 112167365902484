import styled from 'styled-components';

/**
 * props:
 * @param {string} props.size - the size of the checkbox (default 1rem)
 */
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    /* tailwindcss: h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 */

    -webkit-appearance: none;
    background-color: white;

    /* h-4 */
    height: ${props => props.size ? props.size : '1rem'};

    /* w-4 */
    width: ${props => props.size ? props.size : '1rem'};

    /* rounded */
    border-radius: 0.25rem; /* 4px */

    /* border-gray-300 */
    border-color: rgb(209 213 219);

    /* text-indigo-600 */
    color: rgb(79 70 229);

    position: relative;

    /* focus:*/
    &:focus {
        /* ring-indigo-600 */
        --tw-ring-color: rgb(79 70 229);
    }

    &:checked {
        background-color: ${props => props.theme.colors.primary()};
        color: white;
    }

    &:checked:before {
        content: "✔";
        color: white;
        position: absolute;
        font-size: ${props => props.size ? props.size : '1rem'};
        text-align: center;
        width: 100%;
        transform: translate(0, -13%);
    }
`