import styled from "styled-components"

export const Select = styled.select`
    //tailwind: block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6
    border-radius: 0.375rem; /* 6px */
    border: 0px;
    padding-top: 0.375rem; /* 6px */
    padding-left: 0.75rem; /* 12px */
    padding-right: 2.5rem; /* 40px */
    color: #1a202c;
    /*ring-1*/
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    /*ring-gray-300*/
    --tw-ring-color: rgb(209 213 219);


    &:focus {
        /*ring-2*/
        box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        /*ring-indigo-600*/
        --tw-ring-color: rgb(79 70 229);
    }

    /*sm*/
    @media (min-width: 640px) {
        /*text-sm*/
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
        /*leading-6*/
        line-height: 1.5rem; /* 24px */
    }

`