import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

body {
    font-family: "Kanit", Arial, Helvetica, sans-serif;
}

.sc-bg-bg {
    background-color: ${props => props.theme.colors.bg()};
}

.sc-bg-success {
    background-color: ${props => props.theme.colors.success()};
}

`

export default GlobalStyle
