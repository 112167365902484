import { createContext, useContext } from "react"
import { useValue } from "../../../components/base/hooks/useValue"
import load from "./functions/load"
import { useValueRef } from "../../../components/base/hooks/useValueRef"


export const AnalyticsStoreContext = createContext(null)

export const AnalyticsStore = () => useContext(AnalyticsStoreContext)


export const MakeAnalyticsStore = () => {
    const state = {
        HitCounter : useValue("Loading..."),
    }

    return {
        state,
        load: load.bind(state)
    }
}