import { Routes, Route } from 'react-router-dom'
import { Home } from '../pages/Home'
import GlobalStyle from './theme/global-styles'
//import { Header } from './theme/Header'
import { Login } from '../pages/Login'
import { TemplateBuilder } from '../pages/TemplateBuilder'
import { Header } from './theme/Header'
import { Dashboard } from '../pages/Dashboard'
import { PrivateRoute } from '../utils/PrivateRoute'
import { useEffect } from 'react'
import authReducer from './reducers/auth'
import api, { setAuthToken } from '../utils/api'
import { TrackPage } from '../utils/analytics'
import { useWinningNumbersStore } from '../stores/GlobalWinningNumbersStore'

export default function App() {

  const winningNumbersStore = useWinningNumbersStore()

  useEffect(() => {
    const storedToken = localStorage.getItem('token')
    if(storedToken)
    {
      console.log("stored token:", storedToken)
      setAuthToken(storedToken)

      api.get('/auth/user').then(({data}) => {
        authReducer.loadUser(data)
      }).catch(err => {
        console.log("/auth/user failed:", err)
      })
    }
    else
    {
      authReducer.logout()
    }

    if(process.env.REACT_APP_DEV_MODE.toString() !== '1')
    {
      api.post('/page-counter',{time: (new Date()).getTime()})
    }

    winningNumbersStore.loadWinningNumbers()
    
  },[])

  return (
    <>
      <GlobalStyle />

      <Header />

      <Routes>
        <Route exact path='/' element={<TrackPage><Home /></TrackPage>} />
        <Route path='/login' element={<TrackPage><Login /></TrackPage>} />
        <Route path='/dashboard' element={<PrivateRoute accoutType="SUPER_ADMIN"><Dashboard /></PrivateRoute>} />
        <Route path='/template-builder' element={<TemplateBuilder />} />
      </Routes>
    </>
  )
}
