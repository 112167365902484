import { useValue } from "../../../components/base/hooks/useValue"
import { Tooltip } from "../FilterButtons/Style"
import { useLotteryFiltersStore } from "../store"
import { NumberSelector } from "./Style"

export const IntervalSelector = () => {

    const {
        state: {
            FileDownloadFilters,
            NumberCombinationStore
        },
        setPrintInterval
    } = useLotteryFiltersStore()

    const EditMode = useValue(false)

    let selection = ""
    let ErrorMessage = useValue(null)

    const onValueChange = (e) => {

        selection = e.target.value
        if (selection == "") {
            ErrorMessage.set("The print interval cannot be empty.")
            return
        }

        let number = parseInt(selection)
        if (isNaN(number)) {
            ErrorMessage.set("The print interval must be a number.")
            return
        }

        if (number < 1) {
            ErrorMessage.set("The print interval cannot be smaller than 1")
            return
        }
        if (((NumberCombinationStore.state.currentNumber / number) / 5) > 2000) {
            ErrorMessage.set("This would bring the number of cards over 2000. Please reduce the print interval.")
            return
        }
        else if (number > NumberCombinationStore.state.currentNumber) {
            ErrorMessage.set("The print interval cannot be greater than the total number of combinations.")
            return
        }

        ErrorMessage.set(null)
    }

    const setPrintEvery = () => {
        onValueChange({ target: { value: selection } })
        if (ErrorMessage.get() !== null) return
        setPrintInterval(selection)
        EditMode.set(false)
    }

    return (

        <EditMode render={editMode => <>
            {editMode === false &&
                <NumberSelector onClick={() => EditMode.set(true)}>
                    <p className="text-center text-xl pt-2">
                        Print every <span className="text-primary font-bold text-2xl mr-[2px]">
                            <FileDownloadFilters render={filter => filter.printEvery || Math.floor(NumberCombinationStore.state.currentNumber / 100)} />
                        </span>th number combo</p>
                </NumberSelector>
            }
            {editMode === true &&
                <NumberSelector selected={true}>
                    <p className="text-center text-xl pt-2">Print every <span className="text-primary font-bold text-2xl">
                        <input type="number" className="w-28 mx-1 px-2" onChange={onValueChange} />
                    </span>th number combo</p>
                    <div className="flex flex-row-reverse px-3 gap-3 mt-3 relative">
                        <span className="text-base cursor-pointer text-secondary hover:underline " onClick={() => EditMode.set(false)}>Cancel</span>

                        <ErrorMessage render={message => <>
                            {message === null &&
                                <span className="text-base cursor-pointer text-secondary inline-block relative hover:underline" onClick={setPrintEvery}>Save</span>
                            }
                            {message !== null &&
                                <span className="text-base cursor-pointer text-secondary opacity-75 inline-block group">
                                    Save
                                    <Tooltip className="opacity-0 group-hover:opacity-100" top="28px" indicatorLeft="78%">{message}</Tooltip>
                                </span>
                            }
                        </>} />

                    </div>
                </NumberSelector>
            }
        </>} />
    )
}