import { useEffect, useRef } from 'react'
import { Digit, DigitContainer, Digits } from "./Style"

export const SingleDigit = ({currentDigit = 0}) => {

    const digitContainerRef = useRef()

    useEffect(() => {
        digitContainerRef.current.scrollTo({
            left:0,
            top: currentDigit * 56,
            behavior: 'smooth'
        })
    }, [currentDigit])

    return (
        <DigitContainer ref={digitContainerRef}>
            <Digits>
                <Digit>0</Digit>
                <Digit>1</Digit>
                <Digit>2</Digit>
                <Digit>3</Digit>
                <Digit>4</Digit>
                <Digit>5</Digit>
                <Digit>6</Digit>
                <Digit>7</Digit>
                <Digit>8</Digit>
                <Digit>9</Digit>
            </Digits>
        </DigitContainer>
    )
}