import { loadWinningNumbers } from "./functions/loadWinningNumbers"

const MakeGlobalWinningNumbersStore = () => {
    const state = {
        powerballWinningNumbers: {}, // {"##": {"##": {...}}}
        megaMillionsWinningNumbers: {} // {"##": {"##": {...}}}
    }

    return {
        state,
        loadWinningNumbers: loadWinningNumbers(state)
    }
}

export const winningNumbersStore = MakeGlobalWinningNumbersStore()

export const useWinningNumbersStore = () => {
    return winningNumbersStore
}