import { useContext, createContext } from 'react'
import { useValueRef as useValue } from '../../../components/base/hooks/useValueRef'
import selectFilter from './storeActions/selectFilter'
import selectPowerBall from './storeActions/selectPowerBall'
import { MakeNumberCombinationStore } from './NumberCombination'
import selectMegamillions from './storeActions/selectMegamillions'
import applyMininumSpreadFilter from './storeActions/applyMininumSpreadFilter'
import applyMinimumNumberFilter from './storeActions/applyMinimumNumberFilter'
import applyMaximumNumberFilter from './storeActions/applyMaximumNumberFilter'
import applyEvenlySpacedNumbersFilter from './storeActions/applyEvenlySpacedNumbersFilter'
import applyMaxSequenceFilter from './storeActions/applyMaxSequenceFilter'
import applyMaxSequenceGroupsFilter from './storeActions/applyMaxSequenceGroupsFilter'
import selectPowerBalls from './storeActions/selectPowerBalls'
import selectFileOutputType from './storeActions/selectFileOutputType'
import setPrintInterval from './storeActions/setPrintInterval'
import setNumberOfTickets from './storeActions/setNumberOfTickets'
import downloadFile from './storeActions/downloadFile'
import setCountryState from './storeActions/setCountryState'
import setPrinterType from './storeActions/setPrinterType'
import setNumberOfCards from './storeActions/setNumberOfCards'
import downloadCalibrationFile from './storeActions/downloadCalibrationFile'
import loadPriceSettings from './storeActions/loadPriceSettings'
import applyRemovePreviousWinningNumbersFilter from './storeActions/applyRemovePreviousWinningNumbersFilter'


export const LotteryFiltersStoreContext = createContext(null)
export const useLotteryFiltersStore = () => useContext(LotteryFiltersStoreContext)

export const MakeLotteryFilterStore = () => {
    const state = {
        MaxActiveFilter: useValue(1),
        ActiveFilterIndex: useValue(0),
        NumberCombinationStore: MakeNumberCombinationStore(),
        FilterSelections: useValue({
            lottery: null,
            minSpread: null,
            minNumber: null,
            maxNumber: null,
            evenlySpaced: null,
            maxSequence: null,
            maxSequenceGroups: null,
            selectedPowerBalls: [],
            removeWinningNumbers: null
        }),
        PriceSettings: useValue({ enablePayments: true, txtFilePrice: "5.00", pdfFilePrice: "10.00", vipFilePrice: "15.00", allowPrintingOnCard: true }),
        DemoNumberList: useValue([]),
        SelectedFileOutputType: useValue(null), //either "TEXT" or "PRINT"
        FileDownloadFilters: useValue({}), //{printEvery, numberOfTickets, (for pdf)state, (for pdf)numberOfCards, (for pdf)printerType, special1, special2}
        DownloadingFile: useValue(false),
        //CalibrationObject: useValue(null),
        IgnoreCalibration: useValue(false),
        IsPaypalPromptOpen: useValue(false),
        ShowDownloadCompleteMessage: useValue(false),
        isVipOptionSelected: false
    }

    return {
        state,
        selectFilter: selectFilter.bind(state),
        selectPowerBall: selectPowerBall.bind(state),
        selectMegamillions: selectMegamillions.bind(state),
        applyMininumSpreadFilter: applyMininumSpreadFilter.bind(state),
        applyMinimumNumberFilter: applyMinimumNumberFilter.bind(state),
        applyMaximumNumberFilter: applyMaximumNumberFilter.bind(state),
        applyEvenlySpacedNumbersFilter: applyEvenlySpacedNumbersFilter.bind(state),
        applyRemovePreviousWinningNumbersFilter: applyRemovePreviousWinningNumbersFilter.bind(state),
        applyMaxSequenceFilter: applyMaxSequenceFilter.bind(state),
        applyMaxSequenceGroupsFilter: applyMaxSequenceGroupsFilter.bind(state),
        selectPowerBalls: selectPowerBalls.bind(state),
        selectFileOutputType: selectFileOutputType.bind(state),
        setPrintInterval: setPrintInterval.bind(state),
        setNumberOfTickets: setNumberOfTickets.bind(state),
        downloadFile: downloadFile.bind(state),
        setCountryState: setCountryState.bind(state),
        //setPrinterType: setPrinterType.bind(state),
        setNumberOfCards: setNumberOfCards.bind(state),
        downloadCalibrationFile: downloadCalibrationFile.bind(state),
        loadPriceSettings: loadPriceSettings.bind(state),
        getIsVipOptionSelected: () => { let r = state.isVipOptionSelected; state.isVipOptionSelected = false; return r },
        setIsVipOptionSelected: (value = true) => { state.isVipOptionSelected = value }
    }
}