import { useEffect } from "react"
import { useValueRef } from "../../../components/base/hooks/useValueRef"
import { scriptLoader } from "../../../components/base/util/scriptLoader"
import { Loader } from "../../../components/base/elements/Loader"
import api from "../../../utils/api"
import { PaypalButtonsContainer } from "./Style"
import { useLotteryFiltersStore } from "../store"

export const PaymentSection = () => {

    const {
        state: {
            SelectedFileOutputType,
            IsPaypalPromptOpen
        },
        downloadFile,
        getIsVipOptionSelected
    } = useLotteryFiltersStore()
    
    const PaypalLoaded = useValueRef(false)
    let paypalButtons = null
    let intent = "capture"

    const loadPaypal = async () => {
        try
        {
            await scriptLoader.loadScriptFromURL(process.env.REACT_APP_PAYPAL_SDK_URL+`?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&enable-funding=venmo&currency=USD&intent=capture`)
            const isNewVip = getIsVipOptionSelected()
            paypalButtons = window.paypal.Buttons({ //documentation: https://developer.paypal.com/sdk/js/reference
                createOrder: () => {
                    return (async () => {
                        let {data} = await api.post('/orders/create', {fileType: SelectedFileOutputType.get(), isVip: isNewVip})
                        return data.id
                    })()
                },
                onApprove: (paypalData) => {

                    let orderId = paypalData.orderID

                    return (async () => {
                        try
                        {
                            if(intent === "authorize")
                            {
                                let {data} = await api.post('/orders/authorize', {
                                    orderId
                                })
                                console.log("paypal onApprove authorize data:", data)
                                //alert("paypal works")
                                paypalButtons.close()
                                downloadFile({orderId: data.id, isNewVip: isNewVip})
                                IsPaypalPromptOpen.set(false)
                            }
                            else
                            {
                                let {data} = await api.post('/orders/capture', {
                                    orderId
                                })
                                console.log("paypal onApprove "+intent+" data:", data)
                                //alert("paypal works")
                                paypalButtons.close()
                                downloadFile({orderId: data.id, isNewVip: isNewVip})
                                IsPaypalPromptOpen.set(false)
                            }
                            
                        }
                        catch(err)
                        {
                            console.log(err)
                            alert(err.message)
                        }
                    })()
                },
                onCancel: () => {
                    console.log("paypal window was closed...")
                },
                onError: (err) => {
                    console.log("paypal onError:")
                    console.log(err)
                }
            })
            paypalButtons.render('#payment_options')

            PaypalLoaded.set(true)
        }
        catch(err)
        {
            console.log(err)
            alert(err.message)
        }
    }

    useEffect(() => {
        loadPaypal()
    },[])

    return (
        <div>
            <PaypalLoaded render={(paypalLoaded) => <>
                <PaypalButtonsContainer >
                    {!paypalLoaded && <Loader />}
                    <div id="payment_options"></div>
                </PaypalButtonsContainer>
            </>}/>
        </div>
    )
}